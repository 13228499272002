// import React, { useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { Container, Row, Col } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
// import { NodataFound, CatProducts, MobileSortBy, MobileFilters } from '../components/common';
// import CardProduct from "../components/common/Cardproduct";
// import SearchAction from "../redux/actions/SearchAction";
// import { useMediaQuery } from "react-responsive";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { Filters } from '../components/filters';
// const Bestproduct = () => {
//     const dealsdata = useSelector((state) => state.masterdata.dealsdata);
//     const totalProducts = useSelector((state) => state.masterdata.totalProducts); // Assuming total product count is available
//     const { bestproductsdata, getNewArrivalProductdata } = SearchAction();
//     const dispatch = useDispatch();
//     const location = useLocation();
//     const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
//     const [page, setPage] = useState(1);
//     const [hasMore, setHasMore] = useState(true);
//     // Initial setup for header category
//     useEffect(() => {
//         dispatch({
//             type: "setHeadercategory",
//             payload: false,
//         });

//     }, [dispatch]);
//     useEffect(() => {
//         setPage(1)
//         window.scrollTo({ top: 0, left: 0, });
//     }, [location.search])

//     // Fetch products based on query params (`bestseller` or `NewArrival`)
//     useEffect(() => {
//         const queryParams = new URLSearchParams(location.search);

//         const input_data = {
//             page: page
//         }
//         if (queryParams.has("bestseller")) {
//             // Fetch bestseller products

//             dispatch(bestproductsdata(input_data));
//         }
//         if (queryParams.has("NewArrival")) {
//             // Fetch new arrival products
//             dispatch(getNewArrivalProductdata(input_data));
//         }

//     }, [page]);

//     // Handle infinite scroll `hasMore` logic
//     useEffect(() => {
//         if (dealsdata.length >= totalProducts) {
//             setHasMore(false);
//         }
//     }, [dealsdata, totalProducts]);

//     // Function to fetch more data when the user scrolls
//     const fetchMoreData = () => {
//         setPage((prevPage) => prevPage + 1);
//     };

//     return (
//         <Container fluid>

//             {
//                 (dealsdata.hasOwnProperty("filters")) ?
//                     <Row className='mt-4'>
//                         <Col xxl={2} lg={3} sm={12} xs={12}>
//                             {
//                                 !isDesktoporMobile ?
//                                     dealsdata.hasOwnProperty("filters") && <Filters filtersdata={dealsdata.filters} />
//                                     :
//                                     <MobileSortBy />
//                             }
//                         </Col>
//                         <Col xxl={10} lg={10} sm={12} xs={12}>
//                         <InfiniteScroll
//                             dataLength={dealsdata.display_items.products.length}
//                             hasMore={hasMore}
//                             next={fetchMoreData}
//                             scrollThreshold={"50%"}
//                             endMessage={<p style={{ textAlign: "center" }}>No more products to display</p>}
//                             style={{ overflow: "hidden" }}
//                         >
//                                 {dealsdata.display_items && dealsdata.display_items.products && dealsdata.display_items.products.length > 0 ? (
//                                     dealsdata.display_items.products.map((ele) => (
//                                         <Col
//                                             key={ele.productid}
//                                             lg={2}
//                                             md={4}
//                                             sm={4}
//                                             xs={6}
//                                             className="product-list wishlist-col"
//                                         >
//                                             <CardProduct
//                                                 image={ele.product_images[0]}
//                                                 title={ele.product_title}
//                                                 rating={ele.rating}
//                                                 slug={ele.slug}
//                                                 status={ele.type}
//                                                 price={ele.selling_price}
//                                                 discount={ele.discount_percentage}
//                                                 productid={ele.productid}
//                                                 mrp={ele.mrp}
//                                                 stock_status={ele.stock_status}
//                                                 product_type={ele.product_type}
//                                             />
//                                         </Col>
//                                     ))
//                                 ) : (
//                                     <div className='custom-height'></div>
//                                 )}
//                         </InfiniteScroll>
//                         {
//                             isDesktoporMobile && dealsdata.filters.checkbox.length > 0 &&
//                             <MobileFilters />
//                         }
//                           </Col>
//                     </Row>
//                     :
//                     dealsdata.hasOwnProperty("result") ? <NodataFound /> : <div className='custom-height'></div>
//             }
//         </Container>
//     );
// };

// export default Bestproduct;



import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Badge, Row } from 'react-bootstrap';
import { CatProducts, MobileSortBy, MobileFilters, NodataFound } from '../components/common'
import { NavLink } from 'react-router-dom';
import { StateContext } from '../components/context/ManageStates';
import { useScrollTop } from '../hooks';
import { Filters } from '../components/filters';
import { useMediaQuery } from "react-responsive";
import { RiArrowUpDownFill, RiFilter3Line } from 'react-icons/ri'
import { IoClose } from 'react-icons/io5';
import Sheet from 'react-modal-sheet';
import { useSearch, useFilters } from '../hooks';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import SearchAction from '../redux/actions/SearchAction';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
const SearchCat = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { slug } = useParams();

    const [currentactivelen, setactivelen] = useState([]);
    const dispatch = useDispatch()

    const productcatData = useSelector(state => state.catlogdetail.productcatData);
    const selectedItem = useSelector(state => state.catlogdetail.selectedItem);
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    const { bestproductsdata, } = SearchAction();


    const location = useLocation()



    var pathname = location.pathname.split("/")[1];

    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
        dispatch({ type: 'SethasMore', payload: true })
        // dispatch({ type: 'setFilterPage', payload: 1 })
        dispatch({
            type: 'subcategory_data',
            payload: []
        });
        var queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }

        var finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }))

        if (finalInput.length > 0) {
            var finaloutput = finalInput;
        } else {
            var finaloutput = [];
        }
        var input_data = {
            search: slug,
            "limit": 50,
            "filter_update": 1,
            "type": finaloutput.length > 0 ? "filtered" : "non-filtered",
            "finalOutput": finaloutput,
            "filter_attributes": finaloutput,
            "pathname": pathname,
            "filter_categories":
                selectedItem != null ? [selectedItem.split("@")[1]] : [],
            "page": 1,
        }
        // if (finalInput.length > 0) {
            dispatch(bestproductsdata(input_data));
        // }
    }, [slug])

    const { input_data } = useFilters(handle);

    function handle() {
        dispatch(bestproductsdata(input_data));
    }


    return (
        <Container fluid>
            <Helmet>
                <title>{localStorage.getItem("title") + " - " + "Deals"}</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />

            </Helmet>
            {
                (productcatData.hasOwnProperty("filters")) ?
                    <Row className='mt-4'>
                        <Col xxl={2} lg={3} sm={12} xs={12}>
                            {
                                !isDesktoporMobile ?
                                    productcatData.hasOwnProperty("filters") && <Filters filtersdata={productcatData.filters} />
                                    :
                                    <MobileSortBy />
                            }

                        </Col>


                        <CatProducts />


                        {
                            isDesktoporMobile &&
                            <MobileFilters />

                        }

                    </Row>
                    :
                    productcatData.hasOwnProperty("result") ? <NodataFound /> : <div className='custom-height'></div>
            }
        </Container>
    );
};
export default SearchCat;
