import React from "react"
import { Row, Col } from "react-bootstrap";
import { IoStar } from "react-icons/io5";
import { BiSolidCart } from "react-icons/bi";
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import CartAction from "../../redux/actions/CartAction";
import HeadingTitle from '../common/HeadingTitle'
const Popularcomp = ({ data, heading, viewall, button_url }) => {
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const { addToCartProduct } = CartAction();
    const dispatch = useDispatch();
    const addtocart = (id, image, price) => {
        dispatch({
            type: 'setShowSidebar',
            payload: true
        });
        dispatch({
            type: 'setcartsidebardata',
            payload: {
                quantity: "1",
                image: image,
                price: price
            }
        });
        const inputData = {
            "product_id": id,
            "quantity": 1,
            "type": 'product',
            ...(authStatus === false && { "session_id": localStorage.getItem("sessionId") })
        };
        dispatch(addToCartProduct(inputData));
    }
    return (
        <>
            <div className="home-carousel-component-header">
                <div className="Quality-products">
                    {/* <h5 className="Quality-products-details-left1"></h5> */}
                    <h5 className="Quality-products-details-left2">{heading}</h5>
                </div>
                <NavLink className="text-decoration-none" to={button_url}>
                    <h5 className="home-carousel-componentheader-viewall">{viewall}</h5>
                </NavLink>
            </div>
            <Row>
                {
                    data.slice(0, 6).map(ele => {
                        return (
                            <Col lg={4} md={12} className="popular_main" >
                                <div className="popularproductcomp-maindiv">
                                    <NavLink className="text-decoration-none" to={`/product/${ele.slug}`}>
                                        <div className="popularimage_content">
                                            <div>
                                                <img className="popularimage" src={ele.product_images[0]}></img>
                                            </div>

                                            <div className="popular-middle_items">
                                                <div className="ppularproduct-title">
                                                    {ele.product_title}
                                                </div>
                                                {/* <div className="popularporoduct-brand">
                                                    puma
                                                </div> */}
                                                <div className="popular_product_price_rating">
                                                    <div className="popularproduct-price">
                                                        AEC{ele.selling_price}
                                                    </div>
                                                    {/* <div className="product-rating">
                                                        <IoStar className="product-rating-icons" />
                                                        <span className="productrate">(5.0)</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                    <div className={`add-to-cart popular-product-righticon`} data-toggle="tooltip" data-original-title="Move to Wishlist"  >
                                        <div className="productcard-carticon  primaryColor " onClick={() => { addtocart(ele.productid, ele.product_images[0], ele.selling_price) }}>
                                            <BiSolidCart className="product-card-cart-icon" />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                }

            </Row>
        </>

    )
}
export default Popularcomp;