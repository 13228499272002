import React, { useState } from 'react';
import { BsChevronLeft, BsChevronDown } from "react-icons/bs";
import { FaPlus } from "react-icons/fa6";
import { FiMinus } from "react-icons/fi";
import { NavLink } from 'react-router-dom';

const FilterCategories = ({ item }) => {
  const [expandedItems, setExpandedItems] = useState({});

  // Toggles the expanded state for a category
  const toggleExpand = (slug) => {
    setExpandedItems((prev) => ({
      ...prev,
      [slug]: !prev[slug],
    }));
  };

  // Recursive function to render categories
  const renderCategories = (categories, level = 0) => {
    return categories.map((category) => (
      <li key={category.slug} style={{ paddingLeft: `${0 * level}px` }}>
        <div className="list">
          <NavLink to={`/category/${category.slug}`} className="text-decoration-none">
            {category.label}
          </NavLink>
          {category.children?.length > 0 && (
            <div
              className="sub-icon-expand"
              onClick={() => toggleExpand(category.slug)}
            >
              {expandedItems[category.slug] ? <FiMinus /> : <FaPlus />}
            </div>
          )}
        </div>
        {expandedItems[category.slug] && category.children?.length > 0 && (
          <ul className="nested-list">
            {renderCategories(category.children, level + 1)}
          </ul>
        )}
      </li>
    ));
  };

  return (
    <div className="custom-border-bottom">
      <div>
        <div className="filtertop_name">
          <div className="arrow">
            <BsChevronDown />
          </div>
          <NavLink to={`/category/${item.slug}`} className="text-decoration-none">
            <div className="filterTitle toptitle">{item.label}</div>
          </NavLink>
        </div>
      </div>
      <div className="mb-2 filterItem">
        <ul className="filterItems">
          {renderCategories(item.children)}
        </ul>
      </div>
    </div>
  );
};

export default FilterCategories;
