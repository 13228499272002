import React, { useState, useContext, useEffect } from 'react';
import {
    Form,
} from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { AddressForm } from '../common';
import { Modal } from 'react-bootstrap';
import AddressAction from '../../redux/actions/AddressAction';
import { useDispatch, useSelector } from 'react-redux';
import { HiOutlinePlusSm } from "react-icons/hi";
import { Row, Col } from 'react-bootstrap';
const AddressList = React.memo(({ type, showbtn, addressList }) => {
    const dispatch = useDispatch()
    const onchangeadress = useSelector(state => state.addressdata.onchangeadress);
    const [checked, setchecked] = useState();
    const { deleteAddress, updateAddressfun } = AddressAction();

    const openform = useSelector(state => state.addressdata.openform);
    const addressData = useSelector(state => state.addressdata.addressData);

    useEffect(() => {
        if (addressList.length > 0) {
            var io = addressList.filter(ele => ele.default == 1);
            if (io.length > 0) {
                setchecked(io[0].addressId)
            }
        }
    }, [addressList])
    const handleUpdateAddress = (addressId) => {
        setchecked(addressId)
        const input_data = {
            "default": "1",
            "addressId": addressId
        }
        dispatch(updateAddressfun(input_data))
        // dispatch({ type: 'setopenaddressModal', payload: !openaddressModal })
    }

    const handleClose = () => {
        dispatch({ type: 'setopenform', payload: !openform })
    };
    const handleEdit = (addressId) => {
        // dispatch({ type: 'setErrors', payload: {} })
        dispatch({ type: 'setonchangeadress', payload: '' })
        dispatch({ type: 'setopenform', payload: true })
        dispatch({ type: 'setaddressData', payload: addressList.filter(ele => ele.addressId == addressId) })
        dispatch({ type: 'setAddres', payload: '' })
        setchecked(addressId)
    }

    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteAddress(input_data))
    }
    const handlenewform = () => {
        dispatch({ type: 'setAddres', payload: '' })
        dispatch({ type: 'setaddressData', payload: [] })
        dispatch({ type: 'setopenform', payload: !openform })
        dispatch({
            type: 'setFormValues', payload: {
                name: "",
                phone: "",
                // mailid:"",
                address1: "",
                city: "",
                address2: "",
                pincode: "",
                country: "",
                state: "",
                addressId: "0",
                default: 1,
                addresstype: "Delivery"
            }
        })
    }


    return (
        <Row>
            <Col lg={3}>
                <div className='addresstitle_account' onClick={() => handlenewform()}>
                    <HiOutlinePlusSm className='add_address_account' />
                    <div className='add_addrss_title_account'>
                        Add  Address
                    </div>
                </div>
                {
                    (openform && addressData.length <= 0) &&
                    <Modal
                        show={openform}
                        size="sm"
                        className='my-custom-modal'
                        centered
                        onHide={() => handleClose()}
                    >
                        <Modal.Body>
                            <AddressForm addressData={[]} showbtn={true} />
                        </Modal.Body>
                    </Modal>

                }
            </Col>
            {
                addressList && addressList.length > 0 &&
                    addressList.filter(elefilter => elefilter.addresstype == 'Delivery').length > 0 ? addressList.filter(elefilter => elefilter.addresstype == 'Delivery').map((ele, index) => {
                        return (
                            <>
                                {
                                    ((addressData.length > 0) && (openform) && (addressData[0].addressId == ele.addressId)) ?
                                        <>
                                            {
                                                openform &&
                                                <Modal
                                                    show={openform}
                                                    size="sm"
                                                    className='my-custom-modal'
                                                    centered
                                                    onHide={() => handleClose()}
                                                >
                                                    <Modal.Body>
                                                        <AddressForm type={type} showbtn={showbtn} addressData={addressData} />
                                                    </Modal.Body>
                                                </Modal>
                                            }
                                            <div className='border-bottom'></div>
                                        </>
                                        :
                                        <Col lg={3}>
                                            <div className='myaddress_account'>
                                                {
                                                    index == 0 &&
                                                    <div className='addresslistdefalut_title'>Default:<img className='addresscompanylogo' src={localStorage.getItem("company_logo")}></img></div>
                                                }
                                                <div className='addresname0'>{ele.name}</div>
                                                <div className='addresname1'>{ele.address1}</div>
                                                <div className='addresname1'>{ele.address2}</div>
                                                <div className='addresname1'>{ele.city},{ele.pincode}</div>
                                                <div className='addresname1'>{ele.country}</div>
                                                <div className='addresname1'><span className='addrestitles'>Phone Number :</span>{ele.phone}</div>
                                                <div className='editandremove'>
                                                    <div className='pe-2 cursorpointer' onClick={() => handleEdit(ele.addressId)} >Edit</div>|<div className='ps-2 pe-2 cursorpointer' onClick={(e) => clicked('address', ele.addressId)}>Remove</div> |
                                                    {
                                                        index != 0 &&
                                                        <div className='ps-2 cursorpointer' onClick={() => { handleUpdateAddress(ele.addressId) }}>Set as Default</div>
                                                    }

                                                </div>
                                            </div>
                                        </Col>
                                    // <div className='accordion-address-body-listitem' >
                                    //     <div className="d-flex justify-content-between" >
                                    //         <div onClick={() => handleEdit(ele.addressId)}>
                                    //             <Form.Check
                                    //                 type="radio"
                                    //                 name="addressType"
                                    //                 value={ele.addressId}
                                    //                 label={ele.name}
                                    //                 onChange={(e) => setchecked(e.target.value)}
                                    //                 checked={checked == ele.addressId}
                                    //                 // defaultChecked={each.default_address ? true : false}
                                    //                 aria-label="radio 1"
                                    //             />
                                    //         </div>
                                    //         <div className="d-flex justify-content-end addressIcon">
                                    //             <BiPencil
                                    //                 size={20}
                                    //                 className="me-1"
                                    //                 onClick={() => handleEdit(ele.addressId)}
                                    //             />
                                    //             <AiOutlineDelete
                                    //                 color=""
                                    //                 size={20}
                                    //                 onClick={(e) => clicked('address', ele.addressId)}
                                    //             />
                                    //         </div>
                                    //     </div>
                                    //     <div className='checkout-list-address'>
                                    //         {ele.address}
                                    //         <div>Hyderabad, {ele.state} - <span>{ele.pincode}</span></div>


                                    //     </div>
                                    // </div>
                                }

                            </>
                        )
                    })
                    :
                    <>
                        {
                            openform &&
                            <Modal
                                show={openform}
                                size="sm"
                                className='my-custom-modal'
                                centered
                                onHide={() => handleClose()}
                            >
                                <Modal.Body>
                                    <AddressForm type={type} showbtn={showbtn} addressData={addressData} />
                                </Modal.Body>
                            </Modal>


                        }
                    </>

            }

        </Row>

    );
});

export default AddressList;