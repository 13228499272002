import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import HeadingTitle from './HeadingTitle';
const Bannercategories = ({ bannercatdata }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 756px)` });
    return (
        <div>
              <HeadingTitle className={"center"} HeadingTitle={bannercatdata[0].section_title} content={bannercatdata[0].section_description} />
              <Row>
          
          <Col lg={6} md={6} sm={12}>
              <div class="single-categorie">
                  <NavLink className='text-decoration-none' to={bannercatdata[1].url}>
                      <div class="cat-img  bannercat-image">
                          <img className='bannerimage cat-imag1' src={bannercatdata[1].card_image}></img>
                          {
                            bannercatdata[1].button_label &&
                            <div className={`bannercat-title primaryColor  `}>{bannercatdata[1].button_label}</div>
                          }
                        
                      </div>
                      </NavLink>

              </div>
              <div class="single-categorie mt-3">
              <NavLink className='text-decoration-none' to={bannercatdata[2].url}>
                  <div class="cat-img  bannercat-image">
                      <img  className='bannerimage cat-imag2' src={bannercatdata[2].card_image}></img>
                      {
                        bannercatdata[2].button_label &&
                        <div className={`bannercat-title primaryColor  `}>{bannercatdata[2].button_label}</div>
                      }
                    
                  </div>
                  </NavLink>
              </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
              <Row>
                  <Col lg={6} md={6} sm={12} className={isMobile ? "mt-3" : ""} >

                      <div class="single-categorie">
                      <NavLink className='text-decoration-none' to={bannercatdata[3].url}>
                          <div class="cat-img bannercat-image">
                              <img  className='bannerimage  cat-imag3' src={bannercatdata[3].card_image}></img>
                              {
                                bannercatdata[3].button_label &&
                                <div className={`bannercat-title primaryColor  `}>{bannercatdata[3].button_label}</div>
                              }
                          
                          </div>
                          </NavLink>
                      </div>
                  </Col>
                  <Col lg={6} md={6} sm={12} className={isMobile ? "mt-3" : ""} >

                      <div class="single-categorie">
                      <NavLink className='text-decoration-none' to={bannercatdata[4].url}>
                          <div class="cat-img  bannercat-image">
                       
                              <img  className='bannerimage cat-imag4 ' src={bannercatdata[4].card_image}></img>
                              {
                                 bannercatdata[3].button_label &&
                                 <div className={`bannercat-title primaryColor  `}>{bannercatdata[4].button_label}</div>
                              }
                             
                          </div>
                          </NavLink>
                      </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} className='mt-3'>
                      <div class="single-categorie">
                      <NavLink className='text-decoration-none' to={bannercatdata[5].url}>
                          <div class="cat-img  bannercat-image">
                              <img  className='bannerimage cat-imag5' src={bannercatdata[5].card_image}></img>
                              {
                                bannercatdata[5].button_label &&
                                <div className={`bannercat-title primaryColor `}>{bannercatdata[5].button_label}</div>

                              }
                             
                          </div>
                          </NavLink>
                      </div>
                  </Col>
              </Row>
          </Col>
      </Row >
        </div>
      
    )
}
export default Bannercategories;