import React, { useContext, useEffect, useState } from 'react';
import { RiArrowUpDownFill, RiFilter3Line } from 'react-icons/ri'
import { IoClose } from 'react-icons/io5';
import Sheet from 'react-modal-sheet';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FaFilter } from "react-icons/fa6";
import Mobilefilternew from './Mobilefilternew';
const MobileSortBy = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const filtertotaldata = useSelector(state => state.catlogdetail.filtertotaldata);
    const productcatFiltersData = useSelector(state => state.catlogdetail.productcatFiltersData);
    const dispatch = useDispatch()

    const { slug } = useParams();

    const [checkedvalue, setchecked] = useState('popularity');

    const [opensortBy, setopensortBy] = useState(false);

    const openfilter = () => {
        dispatch({ type: 'setfiltersbottomsheet', payload: true })
    }

    const opensort = () => {
        setopensortBy(true)
    }
    const openfiltersidebar = () => {
        dispatch({
            type: 'setfilterShowSidebar',
            payload: true
        });
    }

    const handleChange = (e) => {
        setopensortBy(false)
        setchecked(e.target.value)
    }


    return (
        <>
            <div className='mobile-filters-header '>
                <div className='sort-filter-options'>

                    {/* <div className='__sort-filter-options_item' onClick={opensort}>
                        <div className='content'>
                            <RiArrowUpDownFill /> <span>Sort</span>
                        </div>
                    </div> */}
                    {/* <div className='__sort-filter-options_item'>
                        <div className='content'>
                            <RiArrowUpDownFill /> <span>Category</span>

                        </div>
                    </div> */}
                    {/* <div className='__sort-filter-options_item' onClick={openfilter}>
                        <div className='content'>
                            <RiFilter3Line /> <span>Filters</span>
                        </div>
                    </div>
                    <div className='filters-products'>
                        ({productcatFiltersData?.display_items?.products?.length || 0} of {filtertotaldata?.filters || 0} products)
                    </div> */}
                    <div className='Resulttitle'> Result</div>
                    <div className='filtermainsymbol'><FaFilter className='filtersymbol' onClick={openfiltersidebar} /></div>
                </div>
            </div>
            <Sheet isOpen={opensortBy} onClose={() => setopensortBy(false)}
            >
                <Sheet.Container className='custom-filter-modalsheet'>
                    <Sheet.Content>
                        <div className='custom-filter-sheet-haeder'>
                            <div className='filter_title'>Sort</div>
                            <div className='icon' onClick={() => setopensortBy(false)}><IoClose size="20" /></div>
                        </div>

                        <div className='mobileFilter-shortBy'>
                            <div className='mobileFilter-shortBy-list'>

                                <input type='radio' value="popularity" checked={checkedvalue == 'popularity'} className='mobileFilter-shortBy-list-radio' id='Popularity' name='radiobtn' onChange={(e) => handleChange(e)} />
                                <label className='mobileFilter-shortBy-list-content' for='Popularity'>Popularity</label>

                            </div>
                            <div className='mobileFilter-shortBy-list'>
                                <input type='radio' value="high_to_low" checked={checkedvalue == 'high_to_low'} className='mobileFilter-shortBy-list-radio' id='Price:High to Low' name='radiobtn' onChange={(e) => handleChange(e)} />
                                <label className='mobileFilter-shortBy-list-content' for='Price:High to Low'>Price: High to Low</label>

                            </div>
                            <div className='mobileFilter-shortBy-list'>
                                <input type='radio' value="low_to_high" checked={checkedvalue == 'low_to_high'} className='mobileFilter-shortBy-list-radio' id='Price:Low To High' name='radiobtn' onChange={(e) => handleChange(e)} />
                                <label className='mobileFilter-shortBy-list-content' for='Price:Low To High'>Price: Low To High</label>

                            </div>
                            <div className='mobileFilter-shortBy-list'>
                                <input type='radio' value="discount" checked={checkedvalue == 'discount'} className='mobileFilter-shortBy-list-radio' id='Discount' name='radiobtn' onChange={(e) => handleChange(e)} />
                                <label className='mobileFilter-shortBy-list-content' for='Discount'>Discount</label>

                            </div>
                            <div className='mobileFilter-shortBy-list'>
                                <input type='radio' value="all_products" checked={checkedvalue == 'all_products'} className='mobileFilter-shortBy-list-radio' id='All Products' name='radiobtn' onChange={(e) => handleChange(e)} />
                                <label className='mobileFilter-shortBy-list-content' for='All Products'>All Products</label>

                            </div>
                        </div>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>
            <Mobilefilternew/>
        </>


    );
};

export default MobileSortBy;