import React, { useState, useContext } from 'react';
import { InputBox, OtpForm } from '../../components/common';
import validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation';
import { useOrders } from '../../hooks';
import { BsFillForwardFill, BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';

import MasterAction from '../../redux/actions/MasterAction';
import AuthAction from '../../redux/actions/AuthAction';

import bubble1 from "../../../src/images/bubble 01.png"
import bubble2 from "../../../src/images/bubble 02.png"
import bubble3 from "../../../src/images/bubblle 03.png"
const ForgotPassword2 = ({ increments, formValue, formValuedata }) => {
    const { varifyOtppassword, } = MasterAction();
    const dispatch = useDispatch();
    const pwd_Email = useSelector(state => state.masterdata.pwd_Email);
    const forgotid = useSelector(state => state.masterdata.forgotid);
    const otpToken = useSelector(state => state.masterdata.otpToken);
    const user = useSelector(state => state.masterdata.user);
    const [formValues, setFormValues] = useState(
        {
            forgot_otp: "",
        }
    )
    const { SignUp } = AuthAction();
    const { values, setValues, setErrors, errors, handleChange, handleSubmit } = useFormvalidation(
        handleNext,
        validate,
        formValues,

    );

    async function handleNext() {
        if (localStorage.getItem("verification_method") == 1) {
            //phone
            // var input_data = {
            //     otp: values.forgot_otp,
            //     mobile: pwd_Email,
            //     token:otpToken
            // }
            if (user) {
                try {
                    var confirmationResult = await user.confirm(values.forgot_otp);
                    if (confirmationResult) {
                        dispatch({ type: 'setStep', payload: 3 })
                        // setOtpVerified(true); 
                        // setEnter(false)
                    } else {
                        // setOtpVerified(false); 
                        // setEnter(true)
                        alert("OTP not verified");
                    }
                } catch (err) {
                    if (err.code === "auth/invalid-verification-code") {
                        setErrors({
                            forgot_otp: "Please enter a valid OTP."
                        })
                        // Handle invalid OTP
                    }
                }


            }
            // dispatch(varifyOtppassword(input_data, errors))
        } else {
            //email
            var input_data = {
                otp: values.forgot_otp,
                email: pwd_Email,
                id: forgotid
            }
            dispatch(varifyOtppassword(input_data, errors))
        }
    }
    const [increse, setIncrese] = useState(false)
    return (
        <div className='ForgotPassword-mian-login'>
            <div className='form_main_container forgotpassword_width'>
                <div className="formsdata_container">


                    {/* <div className='bubble1imag'><img src={bubble1}></img></div>
                    <div className='bubble2imag'><img src={bubble2}></img></div>
                    <div className='bubble3imag'><img src={bubble3}></img></div> */}
                    <div className='fomdataaa'>
                        <div lg={5} className="right-side loginregister">

                            <h4 className="login_text">Password Recovery
                            </h4>
                            <h6 className='loginscreen_goodtosee'>Enter digits code we sent you on your phone number / Email Address</h6>
                            <InputBox label={"Enter OTP"} id={"forgot_otp"} handleChange={handleChange} errors={errors} />


                            <div className='product-detail-buttongroup login-register-btn'>
                                <div className={`product-detail-button form_submit_button_padding primaryColor  `} onClick={handleSubmit} >
                                    Continue
                                </div>
                            </div>
                            <div className='forget-password-need-help' onClick={() => setIncrese(!increse)}>
                                {/* {
                        increse == true ? <BsChevronUp /> : <BsChevronDown />
                    } */}
                                {/* <span className='forget-password-need-help-title'>I need more help</span>  */}
                            </div>
                            {/* {
                    increse == true &&
                    <div className='forget-password-need-help-content'>
                        <div><BsFillForwardFill />If you've already tried to reset your password, but haven't received an email , check your Junk or Spam folder.</div>
                        <div><BsFillForwardFill />If you can't access your email, try resetting that first through your email provider.</div>
                        <div><BsFillForwardFill />If you've recently updated your password, your old password could still be saved in your browser. Try clearing your browser history and re-typing your password.</div>
                        <div><BsFillForwardFill />If that does not work, please try calling us at 180030001593 so that we can help you out.</div>
                    </div>
                } */}
                            {/* <InputBox label={"Email or mobile phone number"} id={"email"} handleChange={handleChange} errors={errors} /> */}


                        </div>
                    </div>



                </div>
            </div>

        </div>

    );
};

export default ForgotPassword2;