const initialState = {
  openModal: false,
  cartDetail: {
    result: []
  },
  cartDetaildup: undefined,
  coupanmsg: [],
  addtocartpage: '',
  error: null,
  bottomsheet: false,
  discount: 0,
  reviewdetails: [],
  vocherdata:[],
  deliveryid:"",
  Coupancode:"",
  onlyfilter:false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'setonlyfilter':
      return {
        ...state,
        onlyfilter: action.payload
      }
    case 'setCoupancode':
      return {
        ...state,
        Coupancode: action.payload
      }
    case 'setAddtocartpage':
      return {
        ...state,
        addtocartpage: action.payload
      }
    case 'setdeliveryid':
      return {
        ...state,
        deliveryid: action.payload
      }
  
    case 'setvocherdata':
      return {
        ...state,
        vocherdata: action.payload
      }
  

    case 'setreviewdetails':
      return {
        ...state,
        reviewdetails: action.payload
      };
    case 'cart_list':
      return {
        ...state,
        cartDetail: action.payload
      };
    case 'setcartDetaildup':
      return {
        ...state,
        cartDetaildup: action.payload
      };
    case 'setCoupanmsg':
      return {
        ...state,
        coupanmsg: action.payload
      };
    case 'setbottomsheet':
      return {
        ...state,
        bottomsheet: action.payload
      };
    case 'setopenModal':
      return {
        ...state,
        openModal: action.payload
      };
    case 'setdiscount':
      return {
        ...state,
        discount: action.payload
      };
    case 'setCoupanmsg':
      return {
        ...state,
        coupanmsg: action.payload
      };
    case 'setdiscount':
      return {
        ...state,
        discount: action.payload
      };
    default:
      return state;
  }
};
