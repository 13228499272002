import React, { useContext, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md'
import { ProfileForm, InputBox } from '../common';
import { StateContext } from '../context/ManageStates';
import validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation'
// import { useAccount } from '../../hooks';
import MyAccountAction from "../../redux/actions/MyAccountAction"
import { useSelector, useDispatch } from 'react-redux';
const OtpForm = () => {
    // const { setopenModal,otpformType, openModal, otpform } = useContext(StateContext)
    const dispatch = useDispatch()
    const otpformType = useSelector(state => state.masterdata.otpformType);
    const openModal = useSelector(state => state.masterdata.openModal);
    const otpform = useSelector(state => state.masterdata.otpform);
    const { sendotp } = MyAccountAction();
    const [errorsotp, setErrorsotp] = useState({});
    const [formValues, setFormValues] = useState(
        otpformType == 'phone' ?
            {
                otp: "",
                mobile: ""
            }
            :
            {
                email: "",
                otp: "",
            }
    )
 

    const { values, errors, handleChange, handleSubmit, handlePictureChange } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );


    function handleNext() {
        if (otpform !== 0 && (!values.otp || values.otp.trim() === '')) {
            setErrorsotp({ otp: 'OTP is required' });
           
            return; // Stop execution if validation fails
        }
        else{
            sendotp(values, errors)
        }
        if (otpform == 0){
            // sendotp(values, errors)
        }
        
        // Login(values, errors)
    }


    return (
        <div className='custom-modal-component'>
            <div className='custom-modal-header'>
                <div className='modal-header-title'>
                    {
                        otpform == 0 ?
                            `${otpformType == 'phone' ? "Enter New Mobile Number" : "Enter New Email Address"}`
                            :
                            "Enter OTP To Change Email Address"
                    }
                </div>
                <div className='custom-modal-closeicon'
                    onClick={() =>
                        dispatch({
                            type: 'setopenModal', payload: !openModal
                        })
                    }
                >
                    <MdOutlineClose size={"20"} />
                </div>
            </div>
            {
                otpform == 0
                    ?

                    otpformType == 'phone' ?
                        <InputBox label={"Phone"} id={"mobile"} key={otpform} handleChange={handleChange} errors={errors} />
                        :
                        <InputBox label={"Email"} id={"email"} key={otpform} handleChange={handleChange} errors={errors} />

                    :
                    <>
                        <InputBox label={"Enter Otp"} id={"otp"} key={otpform} handleChange={handleChange} errors={errors} value={values.otp} />


                        <div className='errorotp'> {errorsotp.otp}</div>
                    </>
            }

            <div className='product-detail-buttongroup send_otp_btn' onClick={handleSubmit}>
                <div className={`product-detail-button primaryColor `}>

                    {
                        otpform == 0 ?
                            " Send OTP for verification"
                            :
                            "Confirm Otp"
                    }
                </div>
            </div>
        </div>
    );
};

export default OtpForm;



// import React, { useState } from 'react';
// import { MdOutlineClose } from 'react-icons/md';
// import { InputBox } from '../common';
// import { useSelector, useDispatch } from 'react-redux';
// import validate from '../../validations/FormValidationRules';
// import useFormvalidation from '../../validations/FormValidation';
// import MyAccountAction from '../../redux/actions/MyAccountAction';

// const OtpForm = () => {
//     const dispatch = useDispatch();
//     const otpformType = useSelector((state) => state.masterdata.otpformType);
//     const openModal = useSelector((state) => state.masterdata.openModal);
//     const otpform = useSelector((state) => state.masterdata.otpform);
//     const { sendotp } = MyAccountAction();

//     const initialValues = otpformType === 'phone'
//         ? { otp: '', mobile: '' }
//         : { email: '', otp: '' };

//     const [errors, setErrors] = useState({});
//     const { values, handleChange, handleSubmit: useFormSubmit } = useFormvalidation(
//         handleNext,
//         validate,
//         initialValues
//     );

//     function handleNext() {
//         sendotp(values, errors);
//     }

//     function handleSubmit(event) {
//         event.preventDefault();

//         // Clear previous errors
//         setErrors({});

//         // Custom validation for OTP field
//         if (otpform !== 0 && (!values.otp || values.otp.trim() === '')) {
//             setErrors({ otp: 'OTP is required' });
//             return; // Stop execution if validation fails
//         }

//         // Custom validation for phone number or email fields
//         if (otpform === 0) {
//             if (otpformType === 'phone' && (!values.mobile || values.mobile.trim() === '')) {
//                 setErrors({ mobile: 'Phone number is required' });
//                 return;
//             }
//             if (otpformType === 'email' && (!values.email || values.email.trim() === '')) {
//                 setErrors({ email: 'Email address is required' });
//                 return;
//             }
//         }

//         // Proceed with the original form submission
//         useFormSubmit(event);
//     }

//     const closeModal = () => {
//         dispatch({ type: 'setopenModal', payload: !openModal });
//     };

//     const renderInput = () => {
//         if (otpform === 0) {
//             return otpformType === 'phone' ? (
//                 <InputBox
//                     label="Phone"
//                     id="mobile"
//                     handleChange={handleChange}
//                     errors={errors}
//                 />
//             ) : (
//                 <InputBox
//                     label="Email"
//                     id="email"
//                     handleChange={handleChange}
//                     errors={errors}
//                 />
//             );
//         } else {
//             return (
//                 <InputBox
//                     label="Enter OTP"
//                     id="otp"
//                     handleChange={handleChange}
//                     errors={errors}
//                     value={values.otp}
//                 />
//             );
//         }
//     };

//     return (
//         <div className="custom-modal-component">
//             <div className="custom-modal-header">
//                 <div className="modal-header-title">
//                     {otpform === 0
//                         ? otpformType === 'phone'
//                             ? 'Enter New Mobile Number'
//                             : 'Enter New Email Address'
//                         : 'Enter OTP To Change Email Address'}
//                 </div>
//                 <div className="custom-modal-closeicon" onClick={closeModal}>
//                     <MdOutlineClose size="20" />
//                 </div>
//             </div>
//             <form onSubmit={handleSubmit}>
//                 {renderInput()}
//                 <div className="product-detail-buttongroup send_otp_btn">
//                     <button type="submit" className="product-detail-button primaryColor">
//                         {otpform === 0 ? 'Send OTP for verification' : 'Confirm OTP'}
//                     </button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default OtpForm;
