import React, { useContext } from 'react';
import { BsPersonCircle, BsChevronRight, BsFillBoxFill, BsList, BsFileEarmarkTextFill, BsHeartFill, BsPersonVcardFill, BsFillGeoAltFill, BsFillChatDotsFill, BsFillFileTextFill, BsPower } from "react-icons/bs";
import { NavLink } from 'react-router-dom';
import { FaPowerOff } from "react-icons/fa6";
import { useCookie } from '../../hooks';
import { useMediaQuery } from "react-responsive"
import { useSelector } from 'react-redux';
import { SubscriptionComp } from '../common';
import logo from '../../images/bg_image.webp';

const MyAccountComp = () => {
    const authStatus = useSelector(state => state.masterdata.authStatus);
    const profileData = useSelector(state => state.masterdata.profileData);
    const userData = useSelector(state => state.masterdata.userData);
    const masterData = useSelector(state => state.masterdata.masterData);
    const footerdata = useSelector(state => state.masterdata.footerdata);
    const { deleteCookie } = useCookie();
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    return (
        <div>
            {
                isDesktoporMobile &&
                <div className='accounts'>
                <div className={`accounts1 ${userData.hasOwnProperty("cust_type") && userData.cust_type == 1 ? "border-none" : ""}`}>
                    <div className='icon1 primaryColorcolor'>
                        {
                            profileData.length > 0 && profileData[0].profilepic_name != '' ?
                                <img referrerPolicy="no-referrer" className="my-account-img" src={profileData[0].profilepic_name}
                                    onError={e => {
                                        e.currentTarget.src = logo
                                    }} />
                                :
                                <BsPersonCircle />
                        }
                    </div>
                    {
                        authStatus ?
                            <>
                                <div className='content1'>
                                    {profileData.length > 0 && profileData[0]?.name && profileData[0].name !== "null" && (
                                        <div className='content1Head'>{profileData[0].name}</div>
                                    )}
                                    {profileData.length > 0 && profileData[0]?.mailid && profileData[0].mailid !== "null" && (
                                        <div className='content1Mail'>{profileData[0].mailid}</div>
                                    )}
                                    {profileData.length > 0 && profileData[0]?.phone && profileData[0].phone !== "null" && (
                                        <div className='content1Contact'>{profileData[0].phone}</div>
                                    )}
                                </div>
    
    
                                <NavLink to={isDesktoporMobile ? "/my-account/profile" : "/my-account"} className="text-decoration-none">
                                    <div className='edit'>Edit</div>
                                </NavLink>
    
                            </>
                            :
                            <NavLink to="/login" className="my-account-login-btn text-decoration-none">
                                Login / Register
                            </NavLink>
    
                    }
    
                </div>
    
    
    
    
                {
    
                    authStatus && Object.keys("masterData").length > 0 && userData.customer_type == 1 && !masterData.hasOwnProperty("subscription") &&
                    <NavLink to={"/subscription"} className="text-decoration-none">
                        <div className='subscribe-button-mobile'>Subscribe Now</div>
                    </NavLink>
                }
    {
        isDesktoporMobile &&
        <>
    
                <div className='accountList'>
    
                    <div className='iconList primaryColorcolor'>
                        <BsHeartFill />
                    </div>
    
                    <NavLink to={"/my-account/subscription"} className='contentList text-decoration-none'>
                        My Account
                    </NavLink>
    
                    <div className='arrowList'>
                        <BsChevronRight />
                    </div>
                </div>
    
                <div className='accountList'>
    
                    <div className='iconList primaryColorcolor'>
                        < BsFillBoxFill />
                    </div>
                    <NavLink to={"/my-orders"} className='contentList text-decoration-none'>
                        My Orders
                    </NavLink>
    
                    <div className='arrowList'>
                        <BsChevronRight />
                    </div>
                </div>
    
                <div className='accountList'>
    
                    <div className='iconList primaryColorcolor'>
                        <BsHeartFill />
                    </div>
    
                    <NavLink to={"/wishlist"} className='contentList text-decoration-none'>
                        My Wishlist
                    </NavLink>
    
    
                    <div className='arrowList'>
                        <BsChevronRight />
                    </div>
                </div>
    
                <div className='accountList'>
    
                    <div className='iconList primaryColorcolor'>
                        <BsFillGeoAltFill />
                    </div>
                    <NavLink to={"/addresses"} className='contentList text-decoration-none'>
                        My Addresses
                    </NavLink>
    
                    <div className='arrowList'>
                        <BsChevronRight />
                    </div>
                </div>
    
                {/* {
                    footerdata.length > 0 &&
                    footerdata.map(ele => {
                        return (
                            <NavLink to={`/policies/${ele.list_key}`} className="text-decoration-none" >
                                <div className='accountList'>
    
                                    <div className='iconList primaryColorcolor'>
                                        <BsFileEarmarkTextFill />
                                    </div>
                                    <div className='contentList'>
                                        {ele.title}
                                    </div>
                                    <div className='arrowList'>
                                        <BsChevronRight />
                                    </div>
                                </div>
                            </NavLink>
                        )
                    })
                } */}
    
    
                {
                    authStatus &&
                    <div className='accountList' onClick={deleteCookie}>
    
                        <div className='iconList primaryColorcolor'>
                            <FaPowerOff />
                        </div>
                        <div className='contentList'>
                            Sign Out
                        </div>
                        <div className='arrowList'>
    
                            <BsChevronRight />
                        </div>
                    </div>
                }
        
        </>
    }
            </div>
            }
      
        {
            !isDesktoporMobile &&
            <SubscriptionComp/>

        }
           
        </div>
       
    );
};

export default MyAccountComp;