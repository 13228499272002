const initialState = {
  addressList: undefined,
  openaddressModal: false,
  TabExpanded: 1,
  onchangeadress: '',
  customer_email: '',
  opencontact: false,
  geoaddress: [],
  errors: {},
  billingerrors: {},
  address: '',
  addressOpen: 0,
  openform: false,
  addressData: [],
  checkoutnewaddress:false,
  chckoutpaymentonceopen:false,
  opendeliveryinstruction:false,
  saveinstruction:"",
  formValues: {
    name: "",
    phone: "",
    address1: "",
    city: "",
    address2: "",
    country: "",
    pincode: "",
    state: "",
    addressId: "0",
    default: 1,
    addresstype: "Delivery"
  },
  billingformValues: {
    billingname: "",
    billingphone: "",
    billingaddress: "",
    billingpincode: "",
    billingstate: "",
    addressId: "0",
    default: 1,
    addresstype: "Billing"
  },
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'setaddressList':
      return {
        ...state,
        addressList: action.payload
      };
    case 'setsaveinstruction':
      return {
        ...state,
        saveinstruction: action.payload
      };
    case 'setaddressList':
      return {
        ...state,
        addressList: action.payload
      };
      case 'setOpendeliveryinstruction':
        return{
          ...state,
          opendeliveryinstruction:action.payload
        }
    case 'setchckoutpaymentonceopen':
      return {
        ...state,
        chckoutpaymentonceopen: action.payload
      };
    case 'setcheckoutnewaddress':
      return {
        ...state,
        checkoutnewaddress: action.payload
      };
    case "setopencontact":
      return {
        ...state,
        opencontact: action.payload
      }
    case 'setopenaddressModal':
      return {
        ...state,
        openaddressModal: action.payload
      };
    case 'setAddressopen':
      return {
        ...state,
        addressOpen: action.payload
      };
    case 'setopenform':
      return {
        ...state,
        openform: action.payload
      };
    case 'setaddressData':
      return {
        ...state,
        addressData: action.payload
      };
    case 'setcustomer_email':
      return {
        ...state,
        customer_email: action.payload
      };
    case 'setAddres':
      return {
        ...state,
        address: action.payload
      };
    case 'setonchangeadress':
      return {
        ...state,
        onchangeadress: action.payload
      };
    case 'setErrors':
      return {
        ...state,
        errors: action.payload
      };
    case 'setFormValues':
      return {
        ...state,
        formValues: action.payload
      };
    case 'setbillingFormValues':
      return {
        ...state,
        billingformValues: action.payload
      };
    case 'setbillingErrors':
      return {
        ...state,
        billingerrors: action.payload
      };
    case 'setcustomegeoaddress':
      return {
        ...state,
        geoaddress: action.payload
      };
    case 'setTabExpanded':
      return {
        ...state,
        TabExpanded: action.payload
      };
    default:
      return state;
  }
};
