import React, { useEffect, useContext, useState } from 'react';
import { AddressList, MyAccountComp, MobileHeader1, CHeckoutAddress, AddressForm, ReactToast, Loder } from '../components/common';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { HiOutlinePlusSm } from "react-icons/hi";
import Sheet from 'react-modal-sheet';
import { useMediaQuery } from 'react-responsive';
import AddressAction from '../redux/actions/AddressAction';
import MasterAction from '../redux/actions/MasterAction';
import { Modal } from 'react-bootstrap';
import { FaAngleRight } from "react-icons/fa6";
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
const AddressListComp = () => {
    const { getAddressList } = AddressAction();
    const dispatch = useDispatch()
    const { getProfileData } = MasterAction();
    const openform = useSelector(state => state.addressdata.openform);
    const addressData = useSelector(state => state.addressdata.addressData);
    const addressList = useSelector(state => state.addressdata.addressList);
    const addressOpen = useSelector(state => state.addressdata.addressOpen);

    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    useEffect(() => {
        {
            dispatch({ type: 'setAddressopen', payload: 1 })
            dispatch(getAddressList());
            dispatch(getProfileData())
        }
    }, [])
    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
    }, []);
    const handlenewform = () => {
        dispatch({ type: 'setAddres', payload: '' })
        dispatch({ type: 'setaddressData', payload: [] })
        dispatch({ type: 'setopenform', payload: !openform })
        dispatch({
            type: 'setFormValues', payload: {
                name: "",
                phone: "",
                // mailid:"",
                address1: "",
                city: "",
                address2: "",
                pincode: "",
                country: "",
                state: "",
                addressId: "0",
                default: 1,
                addresstype: "Delivery"
            }
        })
    }
    const handleClose = () => {
        dispatch({ type: 'setopenform', payload: !openform })
    };
    return (
        <div>
            <Container>
                <Helmet>
                    <title>{localStorage.getItem("title")} - Address</title>
                    <link rel="canonical" href={window.location.origin + window.location.pathname} />
                    {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
                </Helmet>
                <Row className='custom-margin-top'>
                    <div className='d-flex'>
                        <div className='accout_breadcomp1'>
                            <NavLink className="text-decoration-none" to={'/my-account'}>
                                Your Account <FaAngleRight />
                            </NavLink>
                        </div>
                        <div className='accout_breadcomp2 primaryColorcolor'>
                            {window.location.pathname.slice(1)}
                        </div>
                    </div>
                    <h3 className='Youraddress_title pt-3'>
                        Your Addresses
                    </h3>
                    {/* {
                        !isDesktoporMobile &&
                        <Col lg={4} sm={12} xs={12}>
                            <MyAccountComp />
                        </Col>
                    } */}
                    <Col lg={12} md={12}>
                        <div className='question'>
                            <div className='custom-checkout-accordionbody'>
                                {
                                    addressList && addressList.length == 0 &&
                                    <Col lg={3}>
                                        <div className='addresstitle_account' onClick={() => handlenewform()}>
                                            <HiOutlinePlusSm className='add_address_account' />
                                            <div className='add_addrss_title_account'>
                                                Add  Address
                                            </div>
                                        </div>
                                        {
                                            (openform && addressData.length <= 0) &&
                                            <Modal
                                                show={openform}
                                                size="sm"
                                                className='my-custom-modal'
                                                centered
                                                onHide={() => handleClose()}
                                            >
                                                <Modal.Body>
                                                    <AddressForm addressData={[]} showbtn={true} />
                                                </Modal.Body>
                                            </Modal>

                                        }
                                    </Col>
                           
                                 
                           
                                }
                                         {
                                    addressList != undefined && addressList.length > 0 &&
                                    <>


                                        <AddressList addressList={addressList} type={"fgfgf"} showbtn={true} />
                                    </>
                                }
                            </div>
                        </div>


                    </Col>

                    {
                        isDesktoporMobile &&

                        <Sheet isOpen={true} disableDrag={"yes"}
                        >
                            <Sheet.Container className='custom-checkout-address-modalsheet' >
                                <Sheet.Content >
                                    <MobileHeader1 />
                                    {/* {
                                        loaderstatus &&
                                        <div className='main-backdrop main-backdrop-extra'></div>
                                    } */}
                                    {
                                        addressOpen == 1 &&
                                        <>
                                            <div className='question'>
                                                <div className='custom-checkout-accordionbody'>
                                                    <CHeckoutAddress />
                                                </div>
                                            </div>
                                        </>

                                    }
                                    {
                                        addressOpen == 2 &&
                                        <>
                                            <div className='question' style={{ border: "none" }}>
                                                <AddressForm addressData={[]} />
                                            </div>
                                            <ReactToast />
                                        </>
                                    }
                                    {
                                        addressOpen == 3 &&
                                        <>
                                            <div className='question' style={{ border: "none" }}>
                                                <AddressForm addressData={addressData} />
                                            </div>
                                            <ReactToast />
                                        </>
                                    }

                                </Sheet.Content>
                                <Loder />
                            </Sheet.Container>
                            <Sheet.Backdrop />
                        </Sheet>
                    }

                </Row>
            </Container>

        </div>
    );
};

export default AddressListComp;