import React, { useState, useContext } from 'react'
import { InputBox, OtpForm } from '../../components/common';
import validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation'
import MasterAction from '../../redux/actions/MasterAction';
import { useNavigate } from 'react-router-dom';
import { BsFillForwardFill, BsChevronDown, BsChevronUp } from 'react-icons/bs';
// import { StateContext } from '../components/context/ManageStates';
import { useOrders } from '../../hooks';
import { StateContext } from '../context/ManageStates';
import bubble1 from "../../../src/images/bubble 01.png"
import bubble2 from "../../../src/images/bubble 02.png"
import bubble3 from "../../../src/images/bubblle 03.png"
import { useSelector, useDispatch } from 'react-redux';

const ForgotPassword3 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState(
        {
            change_old_password: "",
            change_new_password: "",
        }
    )

    const { updatepwd, updateAuthPasswordservicedata } = MasterAction()

    // const { pwd_Email } = useContext(StateContext);
    const pwd_Email = useSelector(state => state.masterdata.pwd_Email);
    const forgotid = useSelector(state => state.masterdata.forgotid);
    const forgot_login_type = useSelector(state => state.masterdata.forgot_login_type);
    const forgotsuccess = useSelector(state => state.masterdata.forgotsuccess);
    const { values, errors, handleChange, handleSubmit, } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );


    function handleNext() {
        if (localStorage.getItem("verification_method") == 1) {
            var input_data = {
                mobile: pwd_Email,
                password: values.change_old_password,

            }
            dispatch(updatepwd(input_data))
        } else {
            var input_data = {
                verify_id: forgotid,
                // email: pwd_Email,
                login_type: forgot_login_type,
                new_password: values.change_old_password
            }
            dispatch(updateAuthPasswordservicedata(input_data))
        }
    }
    if (forgotsuccess == "success") {
        navigate("/Login")
    }

    return (
        <div className='ForgotPassword-mian-login'>

            <div className='form_main_container'>
                <div className="formsdata_container">
                    {/* <div className='bubble1imag'><img src={bubble1}></img></div>
                    <div className='bubble2imag'><img src={bubble2}></img></div>
                    <div className='bubble3imag'><img src={bubble3}></img></div> */}
                    <div className='fomdataaa'>
                        <div lg={5} className="right-side loginregister">

                            <h4 className="login_text">Setup New Password
                            </h4>
                            <h6 className='loginscreen_goodtosee'>Please, setup a new password for your account </h6>
                            <InputBox label={"New Password"} id={"change_old_password"} handleChange={handleChange} errors={errors} />
                            <InputBox label={"Repeat Password"} id={"change_new_password"} handleChange={handleChange} errors={errors} />
                            <div className='product-detail-buttongroup login-register-btn'>
                                <div className={`product-detail-button form_submit_button_padding primaryColor `} onClick={handleSubmit} >
                                   Save
                                </div>
                            </div>


                        </div>
                    </div>



                </div>




            </div>


        </div>

    );
};

export default ForgotPassword3;
