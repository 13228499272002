import React, { useEffect, useContext, useState } from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import { InputBox, OtpForm } from '../../components/common';
import { BsCameraFill } from 'react-icons/bs'
import { Link } from 'react-router-dom';
import validate from "../../validations/FormValidationRules";
import useFormvalidation from '../../validations/FormValidation'
import { useMediaQuery } from 'react-responsive';
import logo from '../../images/bg_image.webp';
import { MdModeEdit } from "react-icons/md";
// import { useAccount } from '../../hooks';
import MyAccountAction from "../../redux/actions/MyAccountAction"
import Sheet from 'react-modal-sheet';
import { useSelector, useDispatch } from 'react-redux';

const ProfileForm = () => {

    const { getProfileData, updateProfile } = MyAccountAction();

    const dispatch = useDispatch()


    useEffect(() => {
        getProfileData()
    }, [])
    const [formValues, setFormValues] = useState(
        {
            name: "",
            mailid: "",
            old_password: "",
            new_password: "",
            phone: "",

        }
    )

    const isDesktopOrMobile = useMediaQuery({ query: '(max-width: 991px)' })

    const profileData = useSelector(state => state.masterdata.profileData);
    const updatedProfilephone = useSelector(state => state.masterdata.updatedProfilephone);
    const openModal = useSelector(state => state.cartdetail.openModal);


    useEffect(() => {
        if (profileData.length > 0) {
            setFormValues({ ...formValues, ...profileData[0] })
        }
    }, [profileData])

    // const { Login } = useAuth();


    const { values, errors, imgData, handleChange, handleSubmit, handlePictureChange } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );


    function handleNext() {
        const data = new FormData();
        data.append('name', values.name);
        data.append('mailid', updatedProfilephone.hasOwnProperty("mailid") ? updatedProfilephone.mailid : values.mailid);
        data.append('phone', updatedProfilephone.hasOwnProperty("phone") ? updatedProfilephone.phone : values.phone);
        data.append('profilepic', values.profilepic == undefined ? '' : values.profilepic);
        data.append('old_password', values.old_password);
        data.append('new_password', values.new_password);
        updateProfile(data, errors)
        // Login(values, errors)
    }

    useEffect(() => {
        if (openModal == false) {
            dispatch({ type: 'setotpform', payload: 0 })
            dispatch({ type: 'setotpToken', payload: '' })
        }
    }, [openModal])

    return (
        <div className='customer-profile'>
            <div className='formH'>
                <div className='profile_settingtitle'>Settings</div>
                <div className='profile_profiletitle'>Your Profile</div>
                <div >
                    <img referrerPolicy="no-referrer" className='profileImg' src={imgData.hasOwnProperty("profilepic") ? imgData.profilepic : values.profilepic_name != '' ? values.profilepic_name : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIkAAACJCAMAAAAv+uv7AAAAMFBMVEXk5ueutLfd4OGpr7Ln6erGyszY29yxt7q9wsTBxsjh4+TN0dO2u77Q1NXU19nq7OzimcAIAAADEElEQVR4nO2b25qrIAxGOUROor7/24627pnaqhDqj/uC1RdYXxICEipEo9FoNBqNRqPRaDQaDOhugQWaSBljVJimG31IqN5JvRL9EOgWGxJ9lFq+oG009TNFottqrDJxmCqbDDsaa2DGimGh4A48HnTVVMiceSwpCpVEhnORGatqhCVDZCbgVWg8qtUtCi6SqJFfItpE2EwT6bH5odPlu0H3SBU6bGg7WGSphOzcgPNDHSMkc36AQeF4LEFBeVDPCgkyKJEZE43aCxUzJPPywWyF5LkiUhqICTs5sPQofkhkhJwlMzfhbVAQMWG2tdUEsY5LClZqxPF64hfsDGJDnli73z8Qi2cqEYHsx81kx+T/qZOitYMwYRymXxgQJkU9FrIZc871vyYIEZH79feKxdzrFJxPMB8aJSU7IkTmj3P+ORZ1uGf3Ngc621PHFMGs4QXuZwbuDoV5bNOIBrvCO95H4AUKr+PDquRB/vKBfRSv5Ld8C/Xg3Fzgr0EzS6XGHMFlqEC+uD4gn1RBdpINqQTVG/GcDxF0nUnGqiJOMlRv0PR0GQ9OcK7CPOVNZXbRb4HR2ldMzKuM6J39nRfL6Md75sVPl6BM380MRt00t15NaPqDbjEhCsGMQ+ddjHYhRue73hgl6gnR/DO9i48KeStYLW103RAE3oamMPqPNfPe2rT1g0LKEKkh/zvQdgr13IFGt/eo4Tg0MvaANjep3bcVKRntzbUupIqucR5c+SaFQsn19F9g3EVxmT0KrnC2LvEKF+qL7hzf8d/WLoWcU2sG3z7VYc3MUy7+i8otGqQcq8TSwwuJeF1Eni5lhUvmklLdqpSMe7KfvKBViD+mxqhgIlKiwntowlNhNRbCiSyT23yVa/vIB/lPAK/srHtolztXCFAPybjUwOZmIe8OrmBQwCbvXpKKJo9ccu4DK4QkLyhlw1i+SjokoP3mg2TTZ89wSklPXOokJ+O1Tq3kJPfk3LfJF5CYEYL3vlcSfbZOW3uanBcK8IT0wflITOl6DOfpUfWo9JeJRqPRaDT2+AFKvySqoM9dPAAAAABJRU5ErkJggg=="}

                        onError={e => {
                            e.currentTarget.src = logo
                        }} /></div>
                <div className='profileUpdate secondaryColor'>
                    <input type='file' id='pht' name="profilepic" style={{ display: "none" }} onChange={handlePictureChange}></input>
                    <label for='pht' className='editprofile secondaryColor'><MdModeEdit /></label>
                </div>
            </div>
            <InputBox label={"Name"} id={"name"} handleChange={handleChange} errors={errors} value={values.name} />
            <InputBox label={"email"} id={"mailid"} disabled={true} handleChange={handleChange} errors={errors} value={values.mailid} />
            <InputBox label={"phone"} id={"phone"} handleChange={handleChange} errors={errors} value={values.phone} />
            <InputBox label={"Old Password"} id={"old_password"} handleChange={handleChange} errors={errors} />
            <InputBox label={"New Password"} id={"new_password"} handleChange={handleChange} errors={errors} />
            
            {
                isDesktopOrMobile ?
                    <Row>
                        <div className='buttons-product-detaildiv'>
                            <div className='product-detail-buttongroup' onClick={handleSubmit}>
                                <div className='product-detail-button primaryColor  text-decoration-none'>
                                    Save Changes
                                </div>
                            </div>
                        </div>
                        <Sheet isOpen={openModal}
                        >
                            <Sheet.Container className='custom-otp-modalsheet'>
                                <Sheet.Content>
                                    <OtpForm />
                                </Sheet.Content>
                            </Sheet.Container>
                            <Sheet.Backdrop />
                        </Sheet>
                    </Row>
                    :
                    <div className='product-detail-buttongroup login-register-btn' onClick={handleSubmit}>
                        <div className={`product-detail-button primaryColor `}>
                            Save Changes
                        </div>
                    </div>
            }
        </div>
    );
};

export default ProfileForm;