const initialState = {
  loaderstatus: false,
  loaderstatus1: false,
  cartpagerediredct: false,
  categorylimit: [],
  otpform: 0,
  updatedProfilephone: {},
  otpToken: ' ',
  addon: [],
  navdata: [],
  carouselapi: "",
  openModal: false,
  authStatus: false,
  userData: {},
  apicarouseldata: [],
  fullcarouselapidata: [],
  productvarientdata: [],
  pricerange:[],
  subscription: [
    {
      "subscriptionid": "7",
      "type": "Year",
      "name": "Basic Plan",
      "duration": "12",
      "price": "60000",
      "description": "<div class=\"short-text\">Everything you need to create your store, ship products, and process payments.</div>\n\n<div class=\"details\">\n<ul>\n\t<li class=\"active\">Online Store</li>\n\t<li class=\"active\">B2B and B2C Pricing</li>\n\t<li class=\"active\">Inventory Management</li>\n\t<li class=\"active\">unlimited&nbsp;Products</li>\n\t<li class=\"active\">Store Management</li>\n\t<li class=\"active\">Supplier Management</li>\n\t<li class=\"active\">CRM</li>\n\t<li class=\"active\">CMS</li>\n\t<li class=\"active\">Analytics</li>\n\t<li class=\"active\">Unlimited Staff accounts</li>\n</ul>\n</div>\n",
      "discount": "0"
    },
    {
      "subscriptionid": "5",
      "type": "Month",
      "name": "Basic Plan",
      "duration": "1",
      "price": "5499",
      "description": "<div class=\"short-text\">Everything you need to create your store, ship products, and process payments.</div>\n\n<div class=\"details\">\n<ul>\n\t<li class=\"active\">Online Store</li>\n\t<li class=\"active\">B2B and B2C Pricing</li>\n\t<li class=\"active\">Inventory Management</li>\n\t<li class=\"active\">Unlimited Products</li>\n\t<li class=\"active\">Store Management</li>\n\t<li class=\"active\">Supplier Management</li>\n\t<li class=\"active\">CRM</li>\n\t<li class=\"active\">CMS</li>\n\t<li class=\"active\">Analytics</li>\n\t<li class=\"active\">Unlimited Staff accounts</li>\n</ul>\n</div>\n",
      "discount": "0"
    },
    {
      "subscriptionid": "12",
      "type": "Month",
      "name": "Business Plan",
      "duration": "1",
      "price": "20000",
      "description": "<div class=\"short-text\">Scale your growing business with a comprehensive suit of features.</div>\n\n<div class=\"details \">\n<ul>\n\t<li class=\"active\" code=\"\">All Basic Plan Features</li>\n\t<li class=\"active\">Point Of Sale</li>\n\t<li class=\"active\">Customizable Storefront Templates</li>\n\t<li class=\"active\">Product Variants</li>\n\t<li class=\"active\">Manual Orders</li>\n\t<li class=\"active\">Custom Shipping Rates</li>\n\t<li class=\"active\">Accounts Management</li>\n\t<li class=\"active\">Coupons &amp; Discounts</li>\n</ul>\n</div>\n",
      "discount": "0"
    },
    {
      "subscriptionid": "11",
      "type": "Year",
      "name": "Business Plan",
      "duration": "12",
      "price": "200000",
      "description": "<div class=\"short-text\">Scale your growing business with a comprehensive suit of features.</div>\n\n<div class=\"details \">\n<ul>\n\t<li class=\"active\" code=\"\">All Basic Plan Features</li>\n\t<li class=\"active\">Point Of Sale</li>\n\t<li class=\"active\">Customizable Storefront Templates</li>\n\t<li class=\"active\">Product Variants</li>\n\t<li class=\"active\">Manual Orders</li>\n\t<li class=\"active\">Custom Shipping Rates</li>\n\t<li class=\"active\">Accounts Management</li>\n\t<li class=\"active\">Coupons &amp; Discounts</li>\n</ul>\n</div>\n",
      "discount": "0"
    },
    {
      "subscriptionid": "8",
      "type": "Year",
      "name": "Ultra Plan",
      "duration": "12",
      "price": "350000",
      "description": "<Script>\n .subscription{\n    content: \" ✔\";\n    color: #69c47e;\n    font-size: 14px;\n    position: absolute;\n    right: 0;\n};\n</Script>\n<div class=\"short-text\">Expand your business with a complete suite of features.</div>\n\n<div class=\"details \">\n<ul>\n\t<li class=\"active\">All Business Plan Features</li>\n\t<li class=\"active\">Product addons</li>\n\t<li class=\"active\">Product Bundles</li>\n\t<li class=\"active\">Accounts Management</li>\n\t<li class=\"active\">Logistics Management</li>\n\t<li class=\"active\">HRIS and Payroll</li>\n\t<li class=\"active\">Audits</li>\n</ul>\n</div>\n",
      "discount": "0"
    },
    {
      "subscriptionid": "6",
      "type": "Month",
      "name": "Ultra Plan",
      "duration": "1",
      "price": "36500",
      "description": "<Script>\n .subscription{\n    content: \" ✔\";\n    color: #69c47e;\n    font-size: 14px;\n    position: absolute;\n    right: 0;\n};\n</Script>\n<div class=\"short-text\">Expand your business with a complete suite of features.</div>\n\n<div class=\"details \">\n<ul>\n\t<li class=\"active\">All Business Plan Features</li>\n\t<li class=\"active\">Product addons</li>\n\t<li class=\"active\">Product Bundles</li>\n\t<li class=\"active\">Accounts Management</li>\n\t<li class=\"active\">Logistics Management</li>\n\t<li class=\"active\">HRIS and Payroll</li>\n\t<li class=\"active\">Audits</li>\n</ul>\n</div>\n",
      "discount": "0"
    },
    {
      "subscriptionid": "10",
      "type": "Month",
      "name": "Pro Plan",
      "duration": "0",
      "price": "0",
      "description": "<h5>Contact sales for custom pricing</h5>\n\n<div class=\"short-text\">Tailored Solutions, Exclusively Crafted for Your Business.</div>\n\n<div class=\"details \">\n<ul>\n\t<li class=\"active\">All Ultra Plan Features</li>\n\t<li class=\"active\">Unlimited products</li>\n\t<li class=\"active\">Custom Analytics</li>\n\t<li class=\"active\">Mobile Applications</li>\n\t<li class=\"active\">Staff KPIs</li>\n\t<li class=\"active\">Multi-Vendor</li>\n\t<li class=\"active\">Advance Audits</li>\n\t<li class=\"active\">Custom Features</li>\n</ul>\n</div>\n",
      "discount": "0"
    },
    {
      "subscriptionid": "9",
      "type": "Year",
      "name": "Pro Plan",
      "duration": "0",
      "price": "0",
      "description": "<h5>Contact sales for custom pricing</h5>\n\n<div class=\"short-text\">Tailored Solutions, Exclusively Crafted for Your Business.</div>\n\n<div class=\"details \">\n<ul>\n\t<li class=\"active\">All Ultra Plan Features</li>\n\t<li class=\"active\">Unlimited products</li>\n\t<li class=\"active\">Custom Analytics</li>\n\t<li class=\"active\">Mobile Applications</li>\n\t<li class=\"active\">Staff KPIs</li>\n\t<li class=\"active\">Multi-Vendor</li>\n\t<li class=\"active\">Advance Audits</li>\n\t<li class=\"active\">Custom Features</li>\n</ul>\n</div>\n",
      "discount": "0"
    }
  ],
  HomePageData: [],
  vendor_token: '',
  profileData: [],
  categoryData: [],
  updatedProfilephone: {},
  sidebar: false,
  masterData: {},
  productdetailimage: "",
  profileData: [],
  error: null,
  categoryOpen: 'level1',
  presntcategoryOpen: "",
  presntsubcategoryOpen: "",
  step: 1,
  pwd_Email: "",
  forgotid: "",
  forgot_login_type: "",
  forgotsuccess: "",
  user: null,
  footerdata: [],
  carusolesmall: 2.3,
  carusolemedium: 3.3,
  carusolelarge: 4,
  carusoleextralarge: 6,
  ratingdata: {},
  langdata: [],
  slectedkeys: '',
  productslug: "",
  NavigationStatus: "",
  multicarouseldata: [],
  selectedmultiplecarousel: [],
  categorylimitdata: [],
  globalsetting: [],
  Headercategory: false,
  subcategoryfilter: [],
  fourproductcarddata: [],
  fourproductcardapidata: [],
  productloadmoredata: [],
  productloadmoreapidata: [],
  showSidebar:false,
  filterShowSidebar:false,
  cartsidebardata:[],
  Deliverydata:[],
  dealsdata:[],
  seeallapi:"",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'setDeliverydata':
      return {
        ...state,
        Deliverydata: action.payload
      }
    case 'setpricerange':
      return {
        ...state,
        pricerange: action.payload
      }
    case 'setseeallapi':
      return {
        ...state,
        seeallapi: action.payload
      }
    case 'setdealsdata':
      return {
        ...state,
        dealsdata: action.payload
      }
    case 'setcategorylimitdata':
      return {
        ...state,
        categorylimitdata: action.payload
      }
    case 'setfilterShowSidebar':
      return {
        ...state,
        filterShowSidebar: action.payload
      }
    case 'setcartsidebardata':
      return {
        ...state,
        cartsidebardata: action.payload
      }
    case 'setShowSidebar':
      return {
        ...state,
        showSidebar: action.payload
      }
    case 'setproductloadmoredata':
      return {
        ...state,
        productloadmoredata: action.payload
      }
    case 'setproductloadmoreapidata':
      return {
        ...state,
        productloadmoreapidata: action.payload
      }
    case 'setfourproductcarddata':
      return {
        ...state,
        fourproductcarddata: action.payload
      }
    case 'setfourproductcardapidata':
      return {
        ...state,
        fourproductcardapidata: action.payload
      }
    case 'setsubcategoryfilter':
      return {
        ...state,
        subcategoryfilter: action.payload
      }
    case 'setHeadercategory':
      return {
        ...state,
        Headercategory: action.payload
      }
    case 'setglobalsetting':
      return {
        ...state,
        globalsetting: action.payload
      }
    case 'setNavigationStatus':
      return {
        ...state,
        NavigationStatus: action.payload
      }
    case 'setmulticarouseldata':
      return {
        ...state,
        multicarouseldata: action.payload
      }
    case 'setselectedmultiplecarousel':
      return {
        ...state,
        selectedmultiplecarousel: action.payload
      }
    case 'setNavigationStatus':
      return {
        ...state,
        NavigationStatus: action.payload
      }
    case 'setproductvarientdata':
      return {
        ...state,
        productvarientdata: action.payload
      }
    case 'setapicarouseldata':
      return {
        ...state,
        apicarouseldata: action.payload
      }
    case 'setfullcarouselapidata':
      return {
        ...state,
        fullcarouselapidata: action.payload
      }
    case 'setcarouselapi':
      return {
        ...state,
        carouselapi: action.payload
      }
    case 'setaddon':
      return {
        ...state,
        addon: action.payload
      }
    case "setcartpagerediredct":
      return {
        ...state,
        cartpagerediredct: action.payload
      }
    case "setforgotid":
      return {
        ...state,
        forgotid: action.payload
      }
    case "setforgotsuccess":
      return {
        ...state,
        forgotsuccess: action.payload
      }
    case "setforgot_login_type":
      return {
        ...state,
        forgot_login_type: action.payload
      }
    case 'setproductslug':
      return {
        ...state,
        productslug: action.payload
      };
    case 'setnavdata':
      return {
        ...state,
        navdata: action.payload
      };
    case 'setProductdetailimage':
      return {
        ...state,
        productdetailimage: action.payload
      }
    case 'setCategorylimit':
      return {
        ...state,
        categorylimit: action.payload
      };
    case 'setslectedkeys':
      return {
        ...state,
        slectedkeys: action.payload
      };
    case 'setlangdata':
      return {
        ...state,
        langdata: action.payload
      };
    case 'setRatingdata':
      return {
        ...state,
        ratingdata: action.payload
      }

    case 'setFooterdata':
      return {
        ...state,
        footerdata: action.payload
      };
    case 'setuser':
      return {
        ...state,
        user: action.payload
      };
    case 'setpwd_Email':
      return {
        ...state,
        pwd_Email: action.payload
      };
    case 'setStep':
      return {
        ...state,
        step: action.payload
      };
    case 'setSubscription':
      return {
        ...state,
        subscription: action.payload
      };
    case 'setupdatedProfilephone':
      return {
        ...state,
        updatedProfilephone: action.payload
      };
    case 'setopenModal':
      return {
        ...state,
        openModal: action.payload
      };
    case 'setotpToken':
      return {
        ...state,
        otpToken: action.payload
      };
    case 'setotpform':
      return {
        ...state,
        otpform: action.payload
      };
    // case 'setLoaderstatus':
    //   return {
    //     ...state,
    //     loaderstatus: action.payload
    //   };
    case 'setLoaderstatus1':
      return {
        ...state,
        loaderstatus1: action.payload
      };
    case 'homepage_detail':
      return {
        ...state,
        HomePageData: action.payload
      };
    case 'masterData':
      return {
        ...state,
        masterData: action.payload
      };
    case 'categorylistData':
      return {
        ...state,
        categoryData: action.payload
      };
    case 'setsidebar':
      return {
        ...state,
        sidebar: action.payload
      };
    case 'setcategoryOpen':
      return {
        ...state,
        categoryOpen: action.payload
      };
    case 'setprofileData':
      return {
        ...state,
        profileData: action.payload
      };
    case 'setauthStatus':
      return {
        ...state,
        authStatus: action.payload
      };
    case 'setuserData':
      return {
        ...state,
        userData: action.payload
      };
    case 'setpresentcategoryOpen':
      return {
        ...state,
        presntcategoryOpen: action.payload
      };
    case 'setpresentsubcategoryOpen':
      return {
        ...state,
        presntsubcategoryOpen: action.payload
      };
    case 'setprofileData':
      return {
        ...state,
        profileData: action.payload
      };
    case 'setvendor_token':
      return {
        ...state,
        vendor_token: action.payload
      };
    case 'setloader':
      return {
        ...state,
        loaderstatus: action.payload
      };
    case 'setupdatedProfilephone':
      return {
        ...state,
        updatedProfilephone: action.payload
      };
    default:
      return state;
  }
};
