import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams, useParams } from "react-router-dom";
import Sheet from "react-modal-sheet";
import { TbShoppingBag } from "react-icons/tb";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Filters } from "../../components/filters";
import { IoCheckmarkOutline } from "react-icons/io5";
import Pricerange from "../common/Pricerange";

const Mobilefilternew = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { slug } = useParams();

    // Redux selectors
    const filterShowSidebar = useSelector((state) => state.masterdata.filterShowSidebar);
    const cartDetail = useSelector((state) => state.cartdetail.cartDetail);
    const productcatData = useSelector((state) => state.catlogdetail.productcatData);

    const checkItems = useSelector((state) => state.catlogdetail.checkItems);

    const [searched, setSearched] = useState({});
    const [currentactivelen, setactivelen] = useState([]);

    // Sync active filters with URL params
    const clearFilterArray = () => {

        productcatData.filters.checkbox.map(ele1 => {
            searchParams.delete(ele1.title);
            setSearchParams(searchParams);
        })
        dispatch({ type: "setfilterShowSidebar", payload: false })
    };
    useEffect(() => {
        let queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }

        const finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }));

        setactivelen(finalInput.length > 0 ? finalInput : []);
    }, [slug, searchParams]);





    // Get active cart item count
    const activeCartItems = cartDetail?.result?.filter((item) => item.cart_status === 1)?.length || 0;

    return (
        <div>
            {filterShowSidebar && (
                <Sheet
                    isOpen={filterShowSidebar}
                    detent="100vh"
                    onClose={() => dispatch({ type: "setfilterShowSidebar", payload: false })}
                >
                    <Sheet.Container className="filtermodalsheet filtercheckoutmobile">
                        <Sheet.Content>
                            {/* Header */}
                            <div className="filtermobiletop">
                                <div
                                    className="icon"
                                    onClick={() => dispatch({ type: "setfilterShowSidebar", payload: false })}
                                >
                                    <FaArrowLeftLong size="20" />
                                </div>
                                <div className="filter_title">Filters</div>
                                <Link to="/cart" className="text-decoration-none">
                                    <div className="cart">
                                        <TbShoppingBag className="filtercartsymbol" />
                                        {/* <span className="custom-badge primaryColor">{activeCartItems}</span> */}
                                    </div>
                                </Link>
                            </div>

                            {/* Price Range Filter */}
                            {/* <div className="mb-3">
                                <Pricerange />
                            </div> */}
                            {
                                productcatData.hasOwnProperty("filters") && <Filters filtersdata={productcatData.filters} />
                            }




                            {/* Footer Buttons */}
                            <div className="filterFooterButtons">
                                <div
                                    className="filterClearButton"
                                    onClick={clearFilterArray}
                                >
                                    Clear 
                                </div>
                                <div
                                    className="filterApplyButton"
                                    onClick={() => dispatch({ type: "setfilterShowSidebar", payload: false })}
                                >
                                    Apply
                                </div>
                            </div>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop />
                </Sheet>
            )}
        </div>
    );
};

export default Mobilefilternew;
