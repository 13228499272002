import React, { useEffect, useState } from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import { InputBox } from '../components/common';
import { Link } from 'react-router-dom';
import logo from '../images/2.png';
import validate from "../validations/FormValidationRules";
import useFormvalidation from '../validations/FormValidation'
import AuthAction from '../redux/actions/AuthAction';
import { useNavigate } from 'react-router-dom';
import OrderAction from '../redux/actions/OrderAction';
import { useSelector, useDispatch } from "react-redux";
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../components/firebase/Setup';

import bubble1 from "../../src/images/bubble 01.png"
import bubble2 from "../../src/images/bubble 02.png"
import { FaArrowLeftLong } from "react-icons/fa6";
import bubble3 from "../../src/images/bubblle 03.png"

import { Helmet } from 'react-helmet';
import Apis from '../services/Apis';
const Register = () => {
    const dispatch = useDispatch()
    const { verifyotpServiceNew } = Apis()
    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
    }, []);
    const navigate = useNavigate();
    const [currentStep, setcurrentStep] = useState(0);
    const [buttonstatus, setButtonstatus] = useState(false);
    const [user, setuser] = useState(null);
    const verification_status = useSelector(state => state.orderdetail.verification_status);
    const emailotp = useSelector(state => state.orderdetail.emailotp);
    const [formValues, setFormValues] = React.useState(

        localStorage.getItem("verification_method") == 1 ? {
            "name": "",
            "phone": "",
            "password": "",
            "otp": "",
            "category": 1
        }
            :
            {
                "name": "",
                "mailid": "",
                "password": "",
                "otp": "",
                "category": 1
            }
    );

    const { SignUp } = AuthAction();
    const { checkemail } = OrderAction();
    const { values, errors, setErrors, handleChange, handleSubmit } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );
    async function awer() {
        if (currentStep == 0) {
            setButtonstatus(true)
            const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {});
            const phoneNumberWithCode = `+91${values.phone}`;
            const confirmation = await signInWithPhoneNumber(auth, phoneNumberWithCode, recaptchaVerifier);
            setcurrentStep(1)
            setuser(confirmation)
            setButtonstatus(false)
        } else {
            if (user) {
                try {
                    var confirmationResult = await user.confirm(values.otp);
                    if (confirmationResult) {
                        dispatch(SignUp(values, setErrors, setcurrentStep))
                        // setOtpVerified(true); 
                        // setEnter(false)
                    } else {
                        // setOtpVerified(false); 
                        // setEnter(true)
                        alert("OTP not verified");
                    }
                } catch (err) {
                    if (err.code === "auth/invalid-verification-code") {
                        setErrors({
                            otp: "Please enter a valid OTP."
                        })
                        // Handle invalid OTP
                    }
                }
            }
        }
    }
    async function callEmailVerification() {
        if (currentStep == 0) {
            setButtonstatus(true)
            setcurrentStep(1)
            setButtonstatus(false)
        } else {
            try {
                var confirmationResult = await verifyotpServiceNew(values);
                if (confirmationResult) {
                    // dispatch(SignUp(values, setErrors, setcurrentStep))
                    // setOtpVerified(true); 
                    // setEnter(false)
                } else {
                    // setOtpVerified(false); 
                    // setEnter(true)
                    alert("OTP not verified");
                }
            } catch (err) {
                if (err.code === "auth/invalid-verification-code") {
                    setErrors({
                        otp: "Please enter a valid OTP."
                    })
                    // Handle invalid OTP
                }
            }
        }
    }
    const loginbackaction = () => {
        navigate(-1)
    }
    useEffect(() => {

        if (verification_status != undefined) {
            if (Object.keys(verification_status).length > 0) {
                setErrors({
                    phone: verification_status.message
                })
            } else {
                if (localStorage.getItem("verification_method") == 1) {
                    awer()
                } else {
                    callEmailVerification()
                }
            }
        }
    }, [verification_status])

    async function confirmOtp(input_data1) {
        const response = await verifyotpServiceNew(input_data1)
        if (response.data.status == 'error') {
            setErrors({
                otp: "Please enter a valid OTP."
            })
        } else {
            dispatch(SignUp(values, setErrors, setcurrentStep))
        }
    }

    async function handleNext() {
        var input_data = {
            email: values.mailid,
            phone: values.phone
        }
        if (currentStep == 0) {
            dispatch(checkemail(input_data))
        } else {
            if (localStorage.getItem("verification_method") != 1) {
                var input_data1 = {
                    email: values.mailid,
                    otp: values.otp,
                    id: emailotp.data,
                }
                confirmOtp(input_data1)
            } else {
                awer()
            }
        }
    }

    return (
        <Container>
            <Helmet>
                <title>{localStorage.getItem("title") + " - " + "Register"}</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            <div className='form_main_container'>
                <div className="formsdata_container">


                    {/* <div className='bubble1imag'><img src={bubble1}></img></div>
                    <div className='bubble2imag'><img src={bubble2}></img></div>
                    <div className='bubble3imag'><img src={bubble3}></img></div> */}
                    <div className='fomdataaa'>
                        <div lg={5} className="right-side loginregister">
                            <div onClick={loginbackaction}>
                                <div className='loginbackbtnicon'><FaArrowLeftLong />
                                    <span className='loginbackbtn' >Back</span>
                                </div>

                            </div>
                            <h4 className="login_text">Create Account </h4>
                            <Row>
                                <Col lg={6}>
                                    <InputBox currentStep={currentStep} label={"Name"} id={"name"} handleChange={handleChange} errors={errors} />
                                </Col>
                                {
                                    localStorage.getItem("verification_method") == 1 ?
                                        <Col lg={6}>
                                            <InputBox currentStep={currentStep} label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} />
                                        </Col>
                                        :
                                        <Col lg={6}>
                                            <InputBox currentStep={currentStep} label={"Email"} id={"mailid"} handleChange={handleChange} errors={errors} />
                                        </Col>
                                }
                                <Col lg={6}>
                                    <InputBox currentStep={currentStep} label={"Password"} id={"password"} handleChange={handleChange} errors={errors} />
                                </Col>

                                {
                                    currentStep == 1 &&
                                    <Col lg={6}>
                                        <InputBox label={"Enter Otp"} id={"otp"} handleChange={handleChange} errors={errors} />
                                    </Col>


                                }
                                {/* {
                               emailotp == "OTP send successfully" &&
                                <Col lg={6}>
                                    <InputBox label={"Enter Otp"} id={"otp"} handleChange={handleChange} errors={errors} />
                                </Col>


                            } */}

                                {
                                    currentStep != 1 && localStorage.getItem("verification_method") == 1 &&

                                    <Col lg={12} className='captcha-div'>

                                        <div id="recaptcha"></div>
                                    </Col>
                                }

                            </Row>

                            <div className={`product-detail-buttongroup normal-register-btn ${buttonstatus ? "disable" : " "}`} >
                                <div className={`product-detail-button form_submit_button_padding  primaryColor`} style={{}} onClick={handleSubmit}>

                                    {currentStep == 0 ? "Let’s Begin" : "Verify Otp"}
                                </div>
                            </div>


                            <div className='login-register'>Already have an account?
                                <Link to="/login" className='signUpNavLink text-decoration-none  text-underline-none2'>
                                    <span className='loginscreen_forgotpassword marginleft'>Log in</span>
                                </Link>
                            </div>

                        </div>
                    </div>



                </div>
            </div>




        </Container>



    );
};

export default Register;