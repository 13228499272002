import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const OrderinstructionForm = () => {
    const dispatch = useDispatch();
    const [formdata, setFormdata] = useState("");
    const saveinstruction = useSelector((state) => state.addressdata.saveinstruction);

    const handleChange = (e) => {
        setFormdata(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: "setsaveinstruction",
            payload: formdata,
        });
        dispatch({
            type: "setOpendeliveryinstruction",
            payload: false,
        });
    };

    const handleCancel = () => {
        setFormdata("");
        dispatch({
            type: "setOpendeliveryinstruction",
            payload: false,
        });
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    Add Additional Instructions for this Order:
                    <textarea
                        name="postContent"
                        value={formdata}
                        onChange={handleChange}
                        rows={4}
                        cols={38}
                    />
                </label>
                <div className="d-flex">
                    <button
                        type="submit"
                        className="primarycolor Submitbtn_instructionform"
                    >
                        Submit
                    </button>
                    <button
                        type="button"
                        className="cancelbtn_instructionform"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default OrderinstructionForm;
