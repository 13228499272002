import React, { useState, useEffect, useContext } from 'react';

import { AddressForm, AddressList, MobileAddressList } from '../common';

import { IoChevronDown } from "react-icons/io5";
import { HiOutlinePlusSm } from "react-icons/hi";

import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from 'react-redux';
import AddressAction from '../../redux/actions/AddressAction';

const CHeckoutAddress = ({ type }) => {

    const dispatch = useDispatch()

    const openform = useSelector(state => state.addressdata.openform);
    const addressData = useSelector(state => state.addressdata.addressData);
    const TabExpanded = useSelector(state => state.addressdata.TabExpanded);
    const addressList = useSelector(state => state.addressdata.addressList);
    const profileData = useSelector(state => state.masterdata.profileData);


    const { getAddressList } = AddressAction();

    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    const [showAddress, setshowAddress] = useState(4);

    const handlenewform = () => {
        dispatch({
            type: 'setAddres', payload: ''
        })
        dispatch({
            type: 'setaddressData', payload: []
        })
        dispatch({
            type: 'setopenform', payload: true
        })
        dispatch({
            type: 'setFormValues', payload: {
                name: profileData[0].name,
                phone: profileData[0].phone,
                // mailid: profileData[0].mailid,
                address1: "",
                address2: "",
                city: "",
                country: "",
                pincode: "",
                state: "",
                addressId: "0",
                default: 1,
                addresstype: "Delivery"
            }
        })
    }

    useEffect(() => {
        if (TabExpanded == 1) {
            dispatch(getAddressList());
        }
    }, [])

    return (
        <div>
            {
                (addressList != undefined && addressList.length > 0) && (

                    isDesktoporMobile ?
                    // <AddressList type={type} addressList={addressList.slice(0, showAddress)} />
                        <MobileAddressList addressList={addressList} />
                        :
                        <AddressList type={type} addressList={addressList.slice(0, showAddress)} />
                )
            }
            {
                (addressList != undefined && addressList.length <= 0) &&
                <AddressForm addressData={[]} />
            }
            {
                (
                    (!isDesktoporMobile) &&
                    <div className={`accrodion-deliver-address-group`}>
                        {

                            (TabExpanded == 1 && addressList != undefined && addressList.length > 4 && addressList.length != showAddress) &&
                            <div className='accordion-deliver-viewmore' onClick={() => setshowAddress(addressList != undefined && addressList.length)}>
                                <IoChevronDown color="#ff7e00" /> view all {addressList.length - 1} address
                            </div>
                        }
                        {
                            addressList != undefined && addressList.filter(elefilter => elefilter.addresstype == 'Delivery').length > 0 && (!openform || addressData.length > 0) &&
                            <div className='accordion-deliver-viewmore' onClick={() => handlenewform()}>
                                <HiOutlinePlusSm color="#ff7e00" style={{ marginTop: "-3px" }} /> Add new Address
                            </div>
                        }
                    </div>
                )
            }
            {
                (openform && addressData.length <= 0) &&
                <AddressForm addressData={[]} />
            }

        </div >

    );
};

export default CHeckoutAddress;