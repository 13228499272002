import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
const FourBanner = ({ fourbannerdata }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 756px)` });
    return (
        <Row>
            <Col lg={6} md={6} sm={12}>
                <div class="single-categorie">
                    <NavLink className='text-decoration-none' to={fourbannerdata[0].url}>
                        <div class="cat-img">
                            <img className='fourbannerimage1' src={fourbannerdata[0].card_image}></img>
                            {
                                fourbannerdata[0].button_label &&
                                <div className={`bannercat-title primaryColor  `}>{fourbannerdata[0].button_label}</div>
                            }

                        </div> </NavLink>
                </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
                <Row>
                    <Col lg={6} md={6} sm={12} className={isMobile ? "mt-3" : ""} >

                        <div class="single-categorie">
                            <NavLink className='text-decoration-none' to={fourbannerdata[1].url}>
                                <div class="cat-img">
                                    <img className='fourbannerimage2'  src={fourbannerdata[1].card_image}></img>
                                    {
                                        fourbannerdata[1].button_label &&
                                        <div className={`bannercat-title primaryColor  `}>{fourbannerdata[1].button_label}</div>
                                    }


                                </div> </NavLink>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className={isMobile ? "mt-3" : ""} >

                        <div class="single-categorie">
                            <NavLink className='text-decoration-none' to={fourbannerdata[2].url}>
                                <div class="cat-img">
                                    <img className='fourbannerimage3'  src={fourbannerdata[2].card_image}></img>
                                    {
                                        fourbannerdata[2].button_label &&
                                        <div className={`bannercat-title primaryColor  `}>{fourbannerdata[2].button_label}</div>
                                    }


                                </div> </NavLink>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} className='mt-3'>
                        <div class="single-categorie">
                            <NavLink className='text-decoration-none' to={fourbannerdata[3].url}>
                                <div class="cat-img">
                                    <img className='fourbannerimage4'  src={fourbannerdata[3].card_image}></img>
                                    {
                                        fourbannerdata[3].button_label && 
                                        <div className={`bannercat-title primaryColor  `}>{fourbannerdata[3].button_label}</div>
                                    }
                                   

                                </div> </NavLink>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default FourBanner;