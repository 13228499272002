import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsChevronLeft, BsSearch } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import SearchAction from '../redux/actions/SearchAction';
import { useSelector, useDispatch } from 'react-redux';
import { MdDeleteForever } from "react-icons/md";
import { RiDeleteBin2Line } from "react-icons/ri";
import logo from '../images/bg_image.webp';
import { NodataFound } from '../components/common'
const Search = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const goBack = () => navigate(-1);
    const [clearHistory, setClearHistory] = useState(false);
    const searchDetail = useSelector(state => state.catlogdetail.searchDetail);
    const showsearchdata = useSelector(state => state.catlogdetail.showsearchdata);

    const [searchValue, setValue] = useState('');
    const { getSearchProduct } = SearchAction();

    const handleChange = (e) => {
        e.preventDefault(); // prevent the default action
        setValue(e.target.value)
        var input_data = {
            "search": e.target.value
        }
        dispatch(getSearchProduct(input_data))
        // setName(e.target.value); // set name to e.target.value (event)
    };


    const searchButton = () => {
        if (searchValue != '') {
            navigate(`/search-result/${searchValue}`)
            let searchHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
            searchHistory.unshift(searchValue);
            if (searchHistory.length > 10) {
                searchHistory.pop()
            }
            localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
        }

    }
    const searchHistoryResults = (item) => {
        navigate(`/search-result/${item}`);
    };

    const clearAllHistory = () => {
        localStorage.removeItem("searchHistory")
        // setClearHistory(true);
    };


    return (
        <div>
            <div className='search'>
                <BsChevronLeft onClick={goBack} size="24" className='search-left-icon' />
                {/* <div className='searchtitle_search'>
                    Search
                </div> */}
                <div className='searchinput'>
                    <input type='text' className="custom-search-input" placeholder='Search here ...' onChange={handleChange} />
                    <BsSearch size="14" onClick={searchButton} className="custom-search-inputicon" />
                </div>

            </div>
            <div className='search-products'>
                <div>
                    {
                        JSON.parse(localStorage.getItem('searchHistory')) && JSON.parse(localStorage.getItem('searchHistory')).length > 0 &&

                        <div className='search-heading'>
                            <h6 className='search-history-title'>Search History</h6>
                            <div className='searchhistory-delete-button' onClick={clearAllHistory} style={{ cursor: 'pointer' }}><MdDeleteForever size={14} /></div>
                        </div>
                    }
                    <div className='searchHistory-main-elements'>
                        {localStorage.getItem("searchHistory") &&
                            JSON.parse(localStorage.getItem("searchHistory")).map((ele, index) => (
                                <h6 className='searchHistory-elements' key={index} onClick={() => searchHistoryResults(ele)}>{ele}</h6>
                            ))
                        }
                    </div>
                </div>

            </div>
            {
                (showsearchdata) && searchDetail.length > 0 ?
                    <div className='searchDetail'>
                        {
                            searchDetail.length > 0 ?

                                searchDetail.map(ele => {
                                    return (
                                        <NavLink onClick={() => {
                                            dispatch({ type: 'setshowdata', payload: false })
                                        }} to={`/product/${ele.slug}`} className="text-decoration-none">
                                            <div className='searchbar-product-list'>
                                                {
                                                    ele.product_images.length > 0 &&
                                                    <img referrerPolicy="no-referrer" src={  ele.product_images[0]}
                                                        onError={e => {
                                                            e.currentTarget.src = logo
                                                        }}
                                                    />
                                                }
                                                <div>{ele.product_title}</div>
                                            </div>
                                        </NavLink>
                                    )
                                })
                                :
                                <div>no record found</div>
                        }
                    </div>
                    :
                    ""

            }

        </div>
    );
};

export default Search;