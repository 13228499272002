import React from "react";
import { MdShoppingBag } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import CartAction from "../../redux/actions/CartAction";

const Vochers = () => {
    const dispatch = useDispatch();
    const vocherdata = useSelector((state) => state.cartdetail.vocherdata);
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const coupanmsg = useSelector((state) => state.cartdetail.coupanmsg);
    const Coupancode = useSelector((state) => state.cartdetail.Coupancode); // Not used, consider removing if unnecessary
    const discount = useSelector((state) => state.cartdetail.discount);
    const { checkcoupan } = CartAction();
    const coupanUpdated = (coupon_code) => {
        const input_data = { coupan_code: coupon_code };
        dispatch(checkcoupan(input_data));
    };
    const removecoupan = () => {
        localStorage.removeItem("coupancode");
        dispatch({
            type: 'setCoupanmsg',
            payload: []
        });
        dispatch({
            type: 'setdiscount',
            payload: 0
        });
    }

    return (
        <div>
            {vocherdata && vocherdata.length > 0 ? (
                vocherdata.map((ele) => (
                    <div className="vochers_main" key={ele.coupon_code}>
                        <div className="vochersubmain">
                            <div className="Vochername">Voucher</div>
                            <div className="vochertop">
                                <div className="voucherdate_reminingdate">
                                    Valid Until {new Date(ele.coupon_end).toLocaleString("en-GB", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                        hour12: false // Use 24-hour format
                                    })}
                                </div>

                            </div>
                        </div>
                        <div className="p-3 vochersecond">
                            <div className="vocher_second_main">
                                <div className="vochersecond_div">
                                    <MdShoppingBag className="vochericon" size={20} /> {ele.coupon_code}
                                </div>
                                <div className="vocherprice">
                                    Minimum Order {localStorage.getItem("currency") + ele.min_order_amt}

                                </div>
                                <div className="vocherprice">
                                    Save {localStorage.getItem("currency") + ele.coupon_amt}
                                </div>

                            </div>
                            {
                              
                                coupanmsg?.coupan_code === ele.coupon_code ? (
                                    <div className="vocher_main_button primaryColor" onClick={() => removecoupan("")}>
                                        Remove
                                    </div>
                                ) : (

                                    <div className="vocher_main_button primaryColor" onClick={() => coupanUpdated(ele.coupon_code)}>
                                        Apply
                                    </div>
                                )}
                        </div>
                        <div >
                            {coupanmsg?.coupan_code === ele.coupon_code ? (
                                <div className="coupanmsgvocher">{coupanmsg.message}</div>
                            ) : (
                                ""
                            )}
                            {
                                cartDetail.total < ele.min_order_amt && (
                                    <div className="coupanmin_ordermsg">
                                        Shop For {localStorage.getItem("currency") + (ele.min_order_amt - cartDetail.total)} More To Apply
                                    </div>
                                )
                            }


                            {/* {coupanmsg?.message && <div className="coupanmsg">{coupanmsg.message}</div>} */}
                        </div>
                        <div className="vocherballright"></div>
                    </div>
                ))
            ) : (
                <div>No vouchers available</div>
            )}

        </div>
    );
};

export default Vochers;
