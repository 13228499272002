import Apis from "../../services/Apis";
import { useDispatch } from "react-redux";
import { notify } from "../../components/common";
import { useSelector } from "react-redux";
const AddressAction = () => {
  const checkoutnewaddress = useSelector(state => state.addressdata.checkoutnewaddress)
  const apidispatch = useDispatch()

  const { addressListService, updateAddress, saveAddress, deleteAddressService } = Apis();

  const getAddressList = () => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await addressListService();

    if (response.status == 200) {
      if (response.data.status == 'success') {
        dispatch({
          type: 'setaddressList',
          payload: response.data.data
        });
        dispatch({
          type: 'setloader',
          payload: false
        });
      } else {
        dispatch({
          type: 'setaddressList',
          payload: []
        });
        dispatch({
          type: 'setloader',
          payload: false
        });
      }
    }
  };

  const addAddress = (input_data, pathname) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    input_data.default = "1"
    const response = await saveAddress(input_data);
    if (response.data.status == "success") {
      dispatch({
        type: 'setaddressData',
        payload: []
      });
      dispatch({
        type: 'setonchangeadress',
        payload: ''
      });
      dispatch({
        type: 'setopenform',
        payload: false
      });

      // notify('add2cart', response.data.message, false)
      if (pathname == '/addresses') {
        dispatch({
          type: 'setAddressopen',
          payload: 1
        });
      } else {
        dispatch({
          type: 'setAddressopen',
          payload: 0
        });
      }
      apidispatch(getAddressList())
      dispatch({
        type: 'setloader',
        payload: false
      });
      dispatch({ type: 'setopencheckoutstatus', payload: 2 })
    } else {
      notify('warning', response.data.message, false)
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };

  const updateAddressfun = (input_data,pathname) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await updateAddress(input_data);
    if (response.data.status == "success") {
      apidispatch(getAddressList())
      dispatch({
        type: 'setaddressData',
        payload: []
      });
      dispatch({
        type: 'setAddressopen',
        payload: 0
      });
      dispatch({
        type: 'setloader',
        payload: false
      });
      if (pathname == '/addresses') {
        dispatch({
          type: 'setAddressopen',
          payload: 1
        });
      } else {
        dispatch({
          type: 'setAddressopen',
          payload: 0
        });
      }
    }
    dispatch({
      type: 'setcheckedbilling',
      payload: false
    });
    dispatch({
      type: 'setloader',
      payload: false
    });
  };

  const deleteAddress = (input_data) => async dispatch => {
    dispatch({
      type: 'setloader',
      payload: true
    });
    const response = await deleteAddressService(input_data);
    if (response.data.status == "success") {
      apidispatch(getAddressList());
      // notify('warning', response.data.message, true)
      dispatch({
        type: 'setloader',
        payload: false
      });
    }
  };


  return {
    getAddressList: getAddressList,
    updateAddressfun: updateAddressfun,
    addAddress: addAddress,
    deleteAddress: deleteAddress
  }
};

export default AddressAction;
