import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import OrderAction from "../../redux/actions/OrderAction";
import logo from '../../images/bg_image.webp';

const PaymentRazarpay = () => {
  const razzer = useSelector(state => state.orderdetail.razzer);
  const selectedColor = useSelector(state => state.orderdetail.selectedColor);
  const dispatch = useDispatch();
  const { getPaymentMethodlist } = OrderAction();

  const handleColorChange = (event) => {
    dispatch({ type: 'setSelectedColor', payload: event.target.value });
  };
  useEffect(() => {
    dispatch(getPaymentMethodlist());
}, []);

  useEffect(() => {
    if (razzer?.length > 0 && !selectedColor) {
      dispatch({ type: 'setSelectedColor', payload: `${razzer[0].id}@${razzer[0].payment_method}` });
    }
  }, [razzer, dispatch, selectedColor]);

  const PaymentOption = ({ payment, index }) => (
    <label htmlFor={`payment-${index}`} className="icons-radio">
      <Row>
        <Col lg={1} md={1} sm={1} xs={1}>
          <input
            className="payment-radio"
            type="radio"
            name="color"
            value={`${payment.id}@${payment.payment_method}`}
            id={`payment-${index}`}
            checked={selectedColor === `${payment.id}@${payment.payment_method}`}
            onChange={handleColorChange}
          />
        </Col>
        <Col lg={7} md={5} sm={10} xs={10}>
          <div className="razzerpay-title">
            {payment.payment_method === "Razorpay" ? "Razorpay Payment Gateway (UPI, Cards & NetBanking)" : payment.payment_method}
          </div>
        </Col>
        {payment.payment_method === "Razorpay" && (
          <Col lg={4} md={5} sm={12} xs={12}>
            {['dcdfe7e1d5626b0a1dda', '0169695890db3db16bfe', '5e3b05b68f3d31b87e84'].map((imgId, imgIndex) => (
              <img
                key={imgIndex}
                referrerPolicy="no-referrer"
                className="razzer-img"
                src={`https://cdn.shopify.com/shopifycloud/checkout-web/assets/${imgId}.svg`}
                alt={`Payment Method ${imgIndex + 1}`}
                onError={(e) => (e.currentTarget.src = logo)}
              />
            ))}
          </Col>
        )}
      </Row>
    </label>
  );

  return (
    <Row>
      {razzer.length > 0 && (
        <div className="Razzerpay">
        
          <div className="payment-razerpay-main">
            {razzer.map((payment, index) => (
              <PaymentOption key={payment.id} payment={payment} index={index} />
            ))}
          </div>
        </div>
      )}
    </Row>
  );
};

export default PaymentRazarpay;
