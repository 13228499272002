import React, { useContext, useEffect, useState } from 'react';
import SearchAction from "../redux/actions/SearchAction";
import { Col, Container, Row } from 'react-bootstrap';
import { NodataFound, CatProducts, MobileSortBy, MobileFilters } from '../components/common';
import { NavLink } from 'react-router-dom';
import { Filters } from '../components/filters';
import { useMediaQuery } from "react-responsive";
import { RiArrowUpDownFill, RiFilter3Line } from 'react-icons/ri'
import { IoClose } from 'react-icons/io5';
import Sheet from 'react-modal-sheet';
import { useSearch, useFilters } from '../hooks';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";


const ProductCat = React.memo(() => {
    const dispatch = useDispatch()
    const { getSubcategorydata } = SearchAction()
    const categorylimit = useSelector(state => state.masterdata.categorylimit);
    const productcatData = useSelector(state => state.catlogdetail.productcatData);
    const onlyfilter = useSelector(state => state.cartdetail.onlyfilter);
    const { slug } = useParams();
    const filterPage = useSelector(state => state.catlogdetail.filterPage);
    const [searchParams, setSearchParams] = useSearchParams();
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const location = useLocation()
    var pathname = location.pathname.split("/")[1];
    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
    }, []);
    useEffect(() => {
        dispatch({
            type: 'SethasMore',
            payload: true
        });
        dispatch({
            type: 'setFilterPage',
            payload: 1
        });
        dispatch({
            type: 'subcategory_data',
            payload: []
        });
        var queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }

        var finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }))
        if (finalInput.length > 0) {
            var finaloutput = finalInput;
        } else {
            var finaloutput = [];
        }
        var input_data = {
            ...(pathname != 'category' && { search: slug }),
            "limit": localStorage.getItem("categorylimit"),
            "filter_update": 1,
            "type": finaloutput.length > 0 ? "filtered" : "non-filtered",
            "finalOutput": finaloutput,
            "filter_attributes": finaloutput,
            "slug": slug,
            "pathname": pathname,
            "page": filterPage,
            // "filter_categories":  pathname == 'products-category' ? [slug] : selectedItem != null ? [selectedItem.split("@")[1]] : []
        }

        dispatch({ type: 'setinput_data', payload: input_data })

        if (finalInput.length > 0) {
            {
                dispatch(getSubcategorydata(input_data))
            }

        }
    }, [slug])

    const { input_data } = useFilters(handle);


    function handle() {
        dispatch(getSubcategorydata(input_data))
    }

    return (
        <Container fluid>
            <Helmet>
                <title>{localStorage.getItem("title") + " - " + slug}</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
            </Helmet>
            {
                (productcatData.hasOwnProperty("filters")) ?
                    <Row className='mt-4'>
                        <Col xxl={2} lg={3} sm={12} xs={12}>
                            {
                                !isDesktoporMobile ?
                                    productcatData.hasOwnProperty("filters") && <Filters filtersdata={productcatData.filters} />
                                    :
                                    <MobileSortBy />
                            }
                        </Col>
                        <CatProducts />
                        {
                            isDesktoporMobile && productcatData.filters.checkbox.length > 0 &&
                            <MobileFilters />
                        }
                    </Row>
                    :
                    productcatData.hasOwnProperty("result") ? <NodataFound /> : <div className='custom-height'></div>
            }
        </Container>
    );
});

export default ProductCat;