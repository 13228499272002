import React, { useState, useContext, useEffect } from 'react';
import {
    Container,
    Form, Row
} from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { StateContext } from '../context/ManageStates';
import { AddressForm, MobileHeader1 } from '../common';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AddressAction from '../../redux/actions/AddressAction';

const AddressList = ({ addressList }) => {
    const dispatch = useDispatch()

    const [checked, setchecked] = useState();

    const { updateAddressfun,deleteAddress } = AddressAction();

    const location = useLocation();

    var pathname = location.pathname;

    const openform = useSelector(state => state.cartdetail.openform);
    const addressData = useSelector(state => state.addressdata.addressData);

    useEffect(() => {
        setchecked(addressList[0].addressId)
    }, [])


    const handleClick = (addressId) => {
        dispatch({ type: 'setopenform', payload: false })
        setchecked(addressId)
    }


    const handleEdit = (addressId) => {
        dispatch({ type: 'setAddressopen', payload: 3 })
        dispatch({ type: 'setaddressData', payload: addressList.filter(ele => ele.addressId == addressId) })
        dispatch({ type: 'setAddres', payload:'' })
        setchecked(addressId)
    }

    const handleUpdateAddress = (addressId) => {
        dispatch({
            type: 'setAddressopen',
            payload: 1
          });
        const input_data = {
            "default": "1",
            "addressId": addressId
        }
        dispatch(updateAddressfun(input_data,location.pathname))
        setchecked(addressId)
    }

    const clicked = (type, id) => {
        const input_data = {
            id: id,
            type: type,
        }
        dispatch(deleteAddress(input_data))
    }

    return (
        <Container fluid>
            {
                addressList && 
                addressList.filter(elefilter => elefilter.addresstype == 'Delivery').map((ele,index) => {
                    return (
                        <>
                            {
                                (addressData.length > 0 && openform && addressData[0].addressId == ele.addressId) ?
                                    <>
                                        <AddressForm addressData={addressData} />
                                        <div className='border-bottom'></div>
                                    </>
                                    :
                                    <>
                                        <div className='myaddress_account'>
                                        {
                                                index ==0 &&
                                                <div className='addresslistdefalut_title'>Default:<img className='addresscompanylogo' src={localStorage.getItem("company_logo")}></img></div>
                                             }
                                            <div className='addresname0'>{ele.name}</div>
                                            <div className='addresname1'>{ele.address1}</div>
                                            <div className='addresname1'>{ele.address2}</div>
                                            <div className='addresname1'>{ele.city},{ele.pincode}</div>
                                            <div className='addresname1'>{ele.country}</div>
                                            <div className='addresname1'><span className='addrestitles'>Phone Number :</span>{ele.phone}</div>
                                            <div className='editandremove'>
                                            <div className='pe-2 cursorpointer' onClick={() => handleEdit(ele.addressId)} >Edit</div>|<div className='ps-2 pe-2 cursorpointer' onClick={(e) => clicked('address', ele.addressId)}>Remove</div> | <div className='ps-2 cursorpointer' onClick={()=>{handleUpdateAddress(ele.addressId)}}>Set as Default</div>                                            </div>
                                        </div>
                                
                                    </>
                                  
                            }

                      

                        </>
                    )
                })
            }
                  <Row>
                                <div className='buttons-product-detaildiv custom-addresslist-bottom-btn' onClick={() => {
                                    dispatch({ type: 'setAddressopen', payload: 2 })
                                    dispatch({ type: 'setAddres', payload: '' })
                                    dispatch({ type: 'setaddressData', payload: [] })
                                    dispatch({
                                        type: 'setFormValues', payload: {
                                            name: "",
                                            phone: "",
                                            address1: "",
                                            address2: "",
                                            city: "",
                                            country: "",
                                            pincode: "",
                                            state: "",
                                            addressId: "0",
                                            default: 1,
                                            addresstype: "Delivery"
                                        }
                                    })

                                }}>
                                    <div className='product-detail-buttongroup'>
                                        <div className='product-detail-button primaryColor text-decoration-none primaryColor'>
                                            Add Address
                                        </div>
                                    </div>
                                </div>

                            </Row>
        </Container>

    );
};

export default AddressList;