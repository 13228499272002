import React, { useState } from 'react';
import { MdOutlineStarPurple500, MdOutlineStarOutline } from "react-icons/md";
import ratingimg from '../../images/ratingimg.png';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ImageModal from './ImageModal';
const RatingNew = () => {
    const reviewdata = useSelector(state => state.cartdetail.reviewdetails);

    const ratingdata = reviewdata.map(ele => ele.rating);
    const totalRatings = reviewdata.filter((ele) => ele.rating).length;
    const [openall, setOpenall] = useState("1")
    const [openallreview, setOpenallreview] = useState(false)
    const [index, setindex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalImages, setModalImages] = useState([]);
    const [initialSlideIndex, setInitialSlideIndex] = useState(0);
    let sum = 0;
    ratingdata.forEach((star) => {
        sum += star;
    });

    const starsavg = sum / totalRatings;
    const allreview = () => {
        setOpenall(totalRatings)
        setOpenallreview(true)
    }
    const lessreview = () => {
        setOpenall("1")
        setOpenallreview(false)
    }
    const handleImageClick = (images, index) => {
        setModalImages(images);
        setInitialSlideIndex(index);
        setShowModal(true);
    };
    return (
        <>
            {
                reviewdata.length > 0 &&
                
                <div className='ratingNewSection'>
                    <div className='rating_and_review_title'>Rating & Reviews</div>
                    <div className=' Ratingnew_star_icons'>

                        {[...Array(5)].map((_, i) => {
                            const ratingValue = i + 1;
                            return (
                                <label key={i}>
                                    <input
                                        type="radio"
                                        name="rating"
                                        value={ratingValue}
                                        style={{ display: 'none' }}
                                    />
                                    {
                                        ratingValue <= starsavg ?

                                            <MdOutlineStarPurple500 size={30} style={{ color: " #ECA61B" }} /> :
                                            <MdOutlineStarOutline size={30} style={{ color: " #ECA61B" }} />

                                    }
                                </label>
                            );
                        })}
                        <div className='averagerating'>{starsavg}/5</div>
                    </div>
                    {
                        reviewdata.slice(0, openall).map(ele => {
                            return (
                                <div className='ratingnew_data'>
                                    <div className='ratingprofileimage'>
                                        <img className='rating_image' src={ratingimg}></img>
                                    </div>
                                    <div>
                                        <div className='d-flex'>
                                            <div className='cusromername'>{ele.customer_name}</div>
                                            <div className='ratindandreviewdate'>
                                                ({new Date(ele.rating_date).toLocaleDateString('en-GB')})

                                            </div>
                                        </div>
                                        <div className='individual_Ratingnew_star_icons'>
                                            {[...Array(5)].map((_, i) => {
                                                const ratingValue = i + 1;
                                                return (
                                                    <label key={i}>
                                                        <input
                                                            type="radio"
                                                            name="rating"
                                                            value={ratingValue}
                                                            style={{ display: 'none' }}
                                                        />
                                                        {
                                                            ratingValue <= ele.rating ?

                                                                <MdOutlineStarPurple500 size={20} style={{ color: " #ECA61B" }} /> :
                                                                <MdOutlineStarOutline size={20} style={{ color: " #ECA61B" }} />

                                                        }
                                                    </label>
                                                );
                                            })}

                                        </div>
                                        <div className='review_data'>
                                            {ele.review}
                                        </div>
                                        {ele.image.map((item, imgIndex) => (
                                    <img
                                        key={imgIndex}
                                        className='Ratingimages'
                                        src={localStorage.getItem("cdn") + "/" + item}
                                        alt={`Review ${index}`}
                                        onClick={() => handleImageClick(ele.image, imgIndex)} // Open modal on click
                                    />
                                ))}
                                    </div>

                                </div>
                            )
                        })
                    }

                    {
                        openallreview ?
                            <Col lg={6} className={`Rating_viewall_button primaryColor`}
                                onClick={() => lessreview()}
                            >
                                View less Reviews
                            </Col> :
                            <Col lg={6} className={`Rating_viewall_button primaryColor`}
                                onClick={() => allreview()}
                            >
                                View All Reviews
                            </Col>
                    }
                         <ImageModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    images={modalImages}
                    initialIndex={initialSlideIndex}
                />


                </div>
            }


        </>
    )
}
export default RatingNew;