import React, { useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { InputBox } from '../components/common';
import { Link } from 'react-router-dom';
import { IoMdHeart } from "react-icons/io";
import { Helmet } from "react-helmet";
import { FaArrowLeftLong, FaApple } from "react-icons/fa6";
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import jwtDecode from "jwt-decode";
import FacebookLogin from '@greatsumini/react-facebook-login';
import apple from "../../src/images/apple.png";
import facebook from "../../src/images/facebook.png";
import logo1 from "../../src/images/Logo (1).png";

const Login = () => {
    const [user, setUser] = useState(null); // State to store Google user info

    const handleGoogleLoginSuccess = (credentialResponse) => {
        try {
            const decoded = jwtDecode(credentialResponse?.credential);
            setUser(decoded); // Store user details
        } catch (error) {
        }
    };

    const handleGoogleLogout = () => {
        googleLogout();
        setUser(null); // Clear user data
    };

    return (
        <Container>
            <Helmet>
                <title>Login - Deals24/7</title>
            </Helmet>
            <div className='form_main_container'>
                <div className="formsdata_container login-width">
                    <div className='fomdataaa'>
                        <div lg={5} className="right-side loginregister">
                            <div onClick={() => window.history.back()}>
                                <div className='loginbackbtnicon'><FaArrowLeftLong />
                                    <span className='loginbackbtn'>Back</span>
                                </div>
                            </div>
                            <h4 className="login_text">Log in</h4>
                            <h6 className='loginscreen_goodtosee'>Good to see you back! <IoMdHeart className='loginheart' /></h6>
                            <Col lg={12}>
                                {/* <InputBox label={"Email"} id={"email"} /> */}
                            </Col>
                            {/* <InputBox label={"Password"} id={"password"} /> */}

                            <div className='product-detail-buttongroup login-register-btn'>
                                <div className='product-detail-button form_submit_button_padding primaryColor'>
                                    Login
                                </div>
                            </div>
                            <div className='loginscreen_forgotpassword'>
                                <Link to="/password-reset" className='signUpNavLink text-decoration-none text-underline-none2'>
                                    Forgot your password?
                                </Link>
                            </div>

                            <div className='mt-5'>
                                {/* Apple Login */}
                                <div className='linkwithggogle-apple'>
                                    Continue with Apple
                                    <FaApple className='linkwithggogle-appleicon' />
                                </div>

                                {/* Facebook Login */}
                                <div className='linkwithggogle-apple'>
                                    Continue with Facebook
                                    {/* <FacebookLogin
                                        appId="YOUR_FACEBOOK_APP_ID"
                                        onSuccess={(response) => console.log("Facebook Login Success:", response)}
                                        onFail={(error) => console.log("Facebook Login Failed:", error)}
                                    /> */}
                                    <img className='linkwithggogle-appleicon' src={facebook} alt="facebook" />
                                </div>

                                {/* Google Login/Logout */}
                                {!user ? (
                                    <div className='linkwithggogle-apple'>
                                        <GoogleLogin
                                            onSuccess={handleGoogleLoginSuccess}
                                            // onError={() => console.log("Google Login Failed")}
                                        />
                                        <img className='linkwithggogle-appleicon' src={logo1} alt="google" />
                                    </div>
                                ) : (
                                    <div className='linkwithggogle-apple' onClick={handleGoogleLogout}>
                                        Logout from Google
                                        <img className='linkwithggogle-appleicon' src={logo1} alt="google" />
                                    </div>
                                )}
                            </div>

                            <div className='login-register'>Don’t have an account?
                                <Link to="/register" className='signUpNavLink text-decoration-none text-underline-none2'>
                                    <span className='loginscreen_forgotpassword marginleft'>Sign up</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Login;
