
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Badge, Row } from 'react-bootstrap';
import { CatProducts, MobileSortBy, MobileFilters, NodataFound } from '../components/common'
import { NavLink } from 'react-router-dom';
import { StateContext } from '../components/context/ManageStates';
import { useScrollTop } from '../hooks';
import { Filters } from '../components/filters';
import { useMediaQuery } from "react-responsive";
import { RiArrowUpDownFill, RiFilter3Line } from 'react-icons/ri'
import { IoClose } from 'react-icons/io5';
import Sheet from 'react-modal-sheet';
import { useSearch, useFilters } from '../hooks';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import SearchAction from '../redux/actions/SearchAction';
import MasterAction from '../redux/actions/MasterAction';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";
const Homeseeall = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { slug } = useParams();

    const [currentactivelen, setactivelen] = useState([]);

    const dispatch = useDispatch()

    const productcatData = useSelector(state => state.catlogdetail.productcatData);
    const apicarouseldata = useSelector(state => state.masterdata.apicarouseldata);
    const seeallapi = useSelector(state => state.masterdata.seeallapi);
    const selectedItem = useSelector(state => state.catlogdetail.selectedItem);

    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });

    // const { Carouselapidatalist, } = SearchAction();
    const { getHomepageData, Carouselapidatalist } = MasterAction()

    const location = useLocation()


    // useEffect(() => {
    //     dispatch(getHomepageData());
    // })
    var pathname = location.pathname.split("/")[1];
    // useMemo(() => {
    //     if (apicarouseldata.component_type == "dynamic" && apicarouseldata.page_component_type == "dynamic") {

    //         const input_data = {
    //             category_ids: apicarouseldata.category_ids
    //         }
    //         dispatch(Carouselapidatalist(input_data, apicarouseldata.api_url))
    //     }
    // }, [apicarouseldata])

    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
        dispatch({ type: 'SethasMore', payload: true })
        // dispatch({ type: 'setFilterPage', payload: 1 })
        dispatch({
            type: 'subcategory_data',
            payload: []
        });
        var queryObj = {};
        for (const entry of searchParams.entries()) {
            queryObj = Object.fromEntries([...searchParams]);
        }

        var finalInput = Object.entries(queryObj).map(([key, value]) => ({
            title: key,
            value: value.split(",").map((ele) => ele.split("_")[0]),
        }))

        if (finalInput.length > 0) {
            var finaloutput = finalInput;
        } else {
            var finaloutput = [];
        }
        var input_data = {
            search: slug,
            "limit": 50,
            "filter_update": 1,
            "type": finaloutput.length > 0 ? "filtered" : "non-filtered",
            "finalOutput": finaloutput,
            "filter_attributes": finaloutput,
            "pathname": pathname,
            "filter_categories":
                selectedItem != null ? [selectedItem.split("@")[1]] : [],
            "page": 1,
            category_ids: slug
        }
        if (localStorage.getItem("fullcarouselapiurl")) {
            dispatch(Carouselapidatalist(input_data, localStorage.getItem("fullcarouselapiurl")))
        }
    }, [])

    // const { input_data } = useFilters(handle);
    // function handle() {
    //     dispatch(Carouselapidatalist(input_data, localStorage.getItem("fullcarouselapiurl")))
    // }
    return (
        <Container fluid>
            <Helmet>
                <title>{localStorage.getItem("title") + " - " + slug}</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />

            </Helmet>
            {
                (productcatData.hasOwnProperty("filters")) ?
                    <Row className='mt-4'>
                        <Col xxl={2} lg={3} sm={12} xs={12}>
                            {
                                !isDesktoporMobile ?
                                    productcatData.hasOwnProperty("filters") && <Filters filtersdata={productcatData.filters} />
                                    :
                                    <MobileSortBy />
                            }
                        </Col>
                        <CatProducts />


                        {
                            isDesktoporMobile &&
                            <MobileFilters />

                        }

                    </Row>
                    :
                    productcatData.hasOwnProperty("result") ? <NodataFound /> : <div className='custom-height'></div>
            }

        </Container>

    );
};

export default Homeseeall;
